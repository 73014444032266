(function () {
	const meta = document.querySelector('[name="viewport"]');
	if (meta) {
		const breakpoint = 1024;
		const desktop = 'width=device-width, initial-scale=1.0';
		const mobile = `width=${breakpoint}`;
		const smallScreen = window.screen.width <= breakpoint;

		if (smallScreen) {
			meta.setAttribute('content', mobile);
			document.documentElement.classList.add('mobile');
		} else {
			meta.setAttribute('content', desktop);
			document.documentElement.classList.add('desktop');
		}

		/** SPAM
		 *
		const handler = () => {
			if (window.innerWidth <= breakpoint) {
				meta.setAttribute('content', mobile);
			} else {
				meta.setAttribute('content', desktop);
			}
		};

		handler();
		window.addEventListener('resize', debounce(handler, 1000));
		 * resize триггерится из-за смены вьюпорта - вешаем дебоунсер

		function debounce(func, delay) {
			let timer;
			return function(arg) {
				clearTimeout(timer);
				timer = setTimeout(() => {
					func.call(this, arg);
				}, delay);
			};
		}
		 */
	}
}());
