class EmailForm {
	constructor() {}
		init() {
			let maxCount = 3;
			let counter = 1;
			const $first = $('.js-email-form-input');
			// иначе разблокирует кнопку на др.стр.
			if (!$first.length) return false;

			const $email = $('.js-email-form-input').clone();

			if (typeof(brief.emails[0]) === "string") {
				$first.val(brief.emails[0]);

				brief.checkValidContinue();
			} else {
				brief.emails.push("");
			}

			$first.change(emailOnChange);

			$('.js-email-form-add').click(function() {
				if (maxCount) {
					maxCount--;
					let $c = $email.clone();

					$c.val("");

					if (typeof(brief.emails[counter]) === "string") {
						$c.val(brief.emails[counter]);
					} else {
						brief.emails.push("");
					}

					$c.attr('data-id', counter++);
					$c.change(emailOnChange);
					$(this).before($c);
				}
			});

			const t = [...brief.emails];
			for (let i = 0; i < t.length - 1; i++) $('.js-email-form-add').click();

			function emailOnChange(ev) {
				let i = $(this).attr('data-id');
				brief.emails[i] = $(this).val();
				brief.save();

				brief.checkValidContinue();
			}
		}
}

module.exports = EmailForm;
