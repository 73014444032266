const { sections } = require('../../../configs/brief.config');

class Modals {
	constructor() {
		this.collection = [];
		this.tergetImg = {
			src: '',
			groupName: '',
			el: null
		};
		this.enableSubmin = false;
		this.oldValue = false;
		this.freeMode = false;
		this.freeModeCallback = null;

		this.final = false;

		this.fieldsetCounter = 0;
		this.fieldsetData = '';
		this.text = '';
	}

	init() {

		document.addEventListener("DOMContentLoaded", () => {
			this.main = document.getElementById('modalWrapper');

			if (this.main) {
				if (document.querySelector('.body--final')) this.final = true;
				// инициализация кнопок отключения
				const closeBtn = this.main.querySelector('#modalClose');
				closeBtn.addEventListener('click', () => {
					this.close();
				}, false);

				this.modalContent = document.querySelector('.modal__content');
				this.clickOutsideHandler = (e) => {
					const state = e.target == this.modalContent || this.modalContent.contains(e.target);
					if (!state && this.main.querySelector('.js-modal.is-active')) {
						this.close();
					}
				};

				window.addEventListener("keydown", e => {
					if (e.key == 'Esc' || e.key == 'Escape') {
						this.close();
					}
				});

				// инициализация интерактивных эл. в модалке
				// const modal = this;

				const commentArea = this.main.querySelector('#modalTextarea');
				const commentSubmit = this.main.querySelector('#modalComment');
				const commentFieldset = this.main.querySelector('#modalFieldset');

				commentFieldset.addEventListener('change', e => {
					if (e.target.checked) {
						this.fieldsetCounter++;
						this.fieldsetData += `${e.target.value}|`;
					} else {
						this.fieldsetCounter--;
						this.fieldsetData = this.fieldsetData.replace(`${e.target.value}|`, '');
					}

					/**
					this.enableSubmin = true;
					commentSubmit.classList.remove('btn--disabled');
					*/

					if (this.fieldsetCounter > 0) {

						this.enableSubmin = true;
						commentSubmit.classList.remove('btn--disabled');

					} else if (this.text.length <= 2 && !this.oldValue) {

						this.enableSubmin = false;
						commentSubmit.classList.add('btn--disabled');

					}
				});

				commentArea.addEventListener('input', e => {
					/**
					 * учесть возможность обнуления уже имеющегося коммента
					 * enableSubmin - дополнительно использован как индикатор состояня (из open() с контентом)
					 *
					 * TODO: валидация и очистка от символов
					*/
					this.text = brief._sanitize(e.target.value);
					if (this.text.length > 2 || this.oldValue) {

						this.enableSubmin = true;
						commentSubmit.classList.remove('btn--disabled');

					} else if (this.text.length <= 2 && !this.oldValue && this.fieldsetCounter <= 0) {

						this.enableSubmin = false;
						commentSubmit.classList.add('btn--disabled');

					}
				});

				commentSubmit.addEventListener('click', ev => {
					// связываем текстовое поле и состояние кнопки
					if (ev.button == 0 && this.enableSubmin) {
						if (!this.freeMode) {
							const group = this.tergetImg.groupName; // str
							const url = this.tergetImg.src; // str
							const element = this.tergetImg.el; // nodeEl

							/**
							 * {
								"0535e20022143d40f752c14ba9a4abc3-png": "text",
								"bf3991133d4eb371825d6fdc8dbd193a-png": "lorem ipsum",
								"gray-jpg": "",
								"a316a3f204435917bcd24570dc5869da-png": "1@#$%^&*()|\\|/",
								"24567831612bd3a1d02244737c325857-png": ""
								}

								brief.imageComments[group][url] = commentArea.value;
								TODO: sanitize
							 */

							brief.imageComments[group][url] = this._text;
							/**
							 * {
							 * "0535e20022143d40f752c14ba9a4abc3-png": "lorem ipsum!^composition|font",
							 * }
							 */
							brief.save();

							this.enableSubmin = false;
							this.oldValue = false;
							commentSubmit.classList.add('btn--disabled');

							// let card = document.querySelector(`[data-target-group="${group}"][iid="${url}"]`); Заменен на сохраненный el

							/** [Правка сценария]
							* Активация "лайка", проверка и провешивания классов
							* перенесена на кнопку "добавить коммент".
							*
							* Код взят из this.toggleSelect(e.currentTarget);
							*/
							if (brief.imageComments[group][url].length) {
								if (!brief.selectedImages[group]) brief.selectedImages[group] = [];
								if (!brief.selectedImages[group].includes(url)) brief.selectedImages[group].push(url);
								element.classList.add('js-selected');

								element.classList.add('js-has-comment');
							} else {
								if (brief.selectedImages[group] && brief.selectedImages[group].includes(url)) {
									let i = brief.selectedImages[group].findIndex(x => x == url);
									if (i >= 0) brief.selectedImages[group].splice(i, 1);
									brief.imageComments[group][url] = '';
								}
								element.classList.remove('js-selected');

								element.classList.remove('js-has-comment');
							}

							/** Final gallery */
							if (this.final && this.tergetImg.el) {
								const fish = this.tergetImg.el.querySelector('.js-final-block-text');
								if (fish) fish.innerText = this.text;

								const checkboxes = [...this.tergetImg.el.querySelectorAll('.js-final-block-checkboxes .form-checkbox__item')];
								if (checkboxes.length) {
									checkboxes.forEach(checbox => {
										if (this.fieldsetData.includes(checbox.getAttribute('data-id-cb'))) {
											checbox.style.display = 'block';
										} else {
											checbox.style.display = 'none';
										}
									});
								}
							}

						} else {
							if (this.freeModeCallback) {
								this.freeModeCallback(this.text);
							}
						}
						brief.save();

						// Закрыть после заполнения
						this.close();
					}
				});

			}

			this.noImage = document.querySelector('.js-modal--no-image');
			if (this.noImage) {
				// перход "дальше" через модалку, если она есть
				// const continueBtn = document.querySelector('#continue');
				// if (continueBtn) {
				// 	continueBtn.addEventListener('click', () => {
				// 		// типа show
				// 		if (!window.brief.galleryesPseudoDisabledState) {
				// 			this.noImage.classList.add('is-active');
				// 		} // иначе случится переход
				// 	});
				// }

				const btns = [...this.noImage.querySelectorAll('.modal--no-image__button')];

				btns.forEach(btn => {
					btn.addEventListener('click', () => {
						this.noImage.classList.remove('is-active');

						if (btn.classList.contains('js-modal-stay')) {
							const continueBtn = document.querySelector('#continue');

							if (continueBtn) {
								continueBtn.classList.remove('js-status-pending')
							}
						} else if (btn.classList.contains('js-modal-next')) {
							brief.gotoNext();
						}
					});
				});
			}

			this.finished = document.querySelector('.js-modal--finished');
			if (this.finished) {
				const btn = this.finished.querySelector('.js-modal-download');

				btn.addEventListener('click', () => {
					if (btn.classList.contains('js-modal-download')) {
						let link = document.createElement('a');
						link.href = this.finished.getAttribute('data-url');
						link.click();
						setTimeout(() => { window.location.reload(); }, 2500);
					}
				});

			}
		});
	}

	get _text() {
		if (this.text.length === 0 && this.fieldsetData.length === 0) {
			return '';
		} else {
			return `${this.text}^${this.fieldsetData}`; // .slice(0, -1)
		}
	}

	initModalCollections(wrapperExemplar) {
		const imgBlockCollection = [...wrapperExemplar.querySelectorAll('.js-modal-collection')];
		imgBlockCollection.forEach(element => {

			element.addEventListener('mouseenter', e => {
				e.target.querySelector('.js-wrapper').classList.add('is-active');
			});

			element.addEventListener('mouseleave', e => {
				e.target.querySelector('.js-wrapper').classList.remove('is-active');
			});

			element.addEventListener('click', e => {
				// const state = e.target.classList.contains('js-helper-targe') || e.target.closest('.js-comment');

				// const state = e.target.classList.contains('js-helper-targe');
				// if (!state) {
				// 	return false;
				// }

				this.tergetImg.src = e.currentTarget.getAttribute('iid');
				this.tergetImg.groupName = e.currentTarget.getAttribute('data-target-group');
				// Допишем и сам эл
				this.tergetImg.el = e.currentTarget;

				if (e.target.classList.contains('js-dislike') && e.currentTarget.classList.contains('js-selected')) {
					console.warn('dislike action!');
					this.toggleSelect(e.currentTarget);
				} else {
					if (e.target.classList.contains('js-like')) {
						console.log('like action!');
					}
					this.open();
				}
			});
		});

	}

	// export
	_getCommentText(group, url) {
		let x = brief.imageComments;
		let result = x[group] && x[group][url]? x[group][url] : '';

		let data = { // crutch
			success: false,
			ov: false,
			fd: '',
			txt: ''
		};

		if (result.length) {
			data.success = true;
			data.ov = true;
			// "0535e20022143d40f752c14ba9a4abc3-png": "lorem ipsum!^composition|font",
			// "0535e20022143d40f752c14ba9a4abc3-png": "^composition|font",
			// "0535e20022143d40f752c14ba9a4abc3-png": "^font",
			const separatorIndex = result.indexOf('^');
			data.fd = separatorIndex < 0 ? '' : result.slice(separatorIndex + 1);
			data.txt = separatorIndex <= 0 ? '' : result.slice(0, separatorIndex);
		}

		return data;
	}

	open() {
		const currentModal = this.main.querySelector('.js-modal:not(.is-active)');
		const group = this.tergetImg.groupName; // str
		const url = this.tergetImg.src; // str

		if (currentModal) {
			currentModal.classList.add('is-active');
			document.querySelector('html').style.overflow = "hidden";

			currentModal.querySelector('img').src = `${BASE_URI}/api/images/get/${this.tergetImg.src}`;

			const data = this._getCommentText(group, url);
			if (data.success) this.oldValue = true;
			this.fieldsetData = data.fd;
			this.text = data.txt;

			currentModal.querySelector('#modalTextarea').value = this.text;
			currentModal.querySelector('#modalTextarea').focus();

			this._fillModalCheckboxes(group);

			setTimeout(() => {
				document.addEventListener('click', this.clickOutsideHandler, false);
			}, 50);
		}
	}

	openFinal(config = {
		src: '',
		groupName: '',
		el: null
	}) {
		this.tergetImg = config;

		const currentModal = this.main.querySelector('.js-modal:not(.is-active)');
		const group = this.tergetImg.groupName; // str
		const url = this.tergetImg.src; // str

		if (currentModal) {
			currentModal.classList.add('is-active');
			document.querySelector('html').style.overflow = "hidden";

			currentModal.querySelector('img').src = `${BASE_URI}/api/images/get/${this.tergetImg.src}`;

			const data = this._getCommentText(group, url);
			if (data.success) this.oldValue = true;
			this.fieldsetData = data.fd;
			this.text = data.txt;

			currentModal.querySelector('#modalTextarea').value = this.text;
			currentModal.querySelector('#modalTextarea').focus();

			this._fillModalCheckboxes(group);

			setTimeout(() => {

				document.addEventListener('click', this.clickOutsideHandler, false);
			}, 50);
		}
	}

	// todo
	forceOpen(src, previousText = '', cb) {
		const currentModal = this.main.querySelector('.js-modal:not(.is-active)');

		if (currentModal) {
			this.freeMode = true;
			if (cb) this.freeModeCallback = cb;

			currentModal.classList.add('is-active');
			currentModal.querySelector('img').src = src;
			currentModal.querySelector('#modalTextarea').value = previousText;
			currentModal.querySelector('#modalTextarea').focus();

			setTimeout(() => {
				document.addEventListener('click', this.clickOutsideHandler, false);
			}, 50);
		}
	}

	close() {
		const currentModal = this.main.querySelector('.js-modal.is-active');

		if (currentModal) {
			document.removeEventListener('click', this.clickOutsideHandler);

			currentModal.querySelector('#modalTextarea').value = '';
			this.text = '';

			this.enableSubmin = false;
			currentModal.querySelector('#modalComment').classList.add('btn--disabled');

			currentModal.classList.remove('is-active');
			document.querySelector('html').style.overflow = "";

			this.fieldsetData = '';
			this.fieldsetCounter = 0;
			currentModal.querySelectorAll('#modalFieldset input').forEach(input => {
				input.checked = false;
			});

			this.tergetImg = {
				src: '',
				groupName: '',
				el: null
			};

			const items = [...this.main.querySelectorAll('#modalFieldset .form-checkbox__item')];
			if (items.length) {
				items.forEach(item => {
					item.classList.add('is-empty');

					let _bufferInput = item.querySelector('input');
					if (_bufferInput) _bufferInput.value = '';

					let _bufferText = item.querySelector('.form-checkbox__txt');
					if (_bufferText) _bufferText.innerText = '';
				})
			}
		}

		this.freeMode = false;
	}

	_fillModalCheckboxes(group = '') {
		const items = [...this.main.querySelectorAll('#modalFieldset .form-checkbox__item')]; /** is-empty скроет эл. в случает несовпадения длины массива*/
		if (!items.length) return false;

		let section = [];
		sections.forEach(sec => { /** Считаем "code" абсолютно уникальным */

			if (sec.code === group && sec.checkboxes) { /** пока не задан дефолный параметр checkboxes: [] */
				section = sec.checkboxes;
			} else if (sec.subsections.length) {
				sec.subsections.forEach(sub => {
					if (sub.code === group && sub.checkboxes) section = sub.checkboxes;
				});
			}

		});
		if (!section.length) return false;

		section.forEach((obj, index) => {
			if (index >= items.length) return false; /** Предосторожность сопоставления массивов */

			items[index].classList.remove('is-empty');

			let _bufferInput = items[index].querySelector('input');
			if (_bufferInput) _bufferInput.value = obj.id;

			let _bufferText = items[index].querySelector('.form-checkbox__txt');
			if (_bufferText) _bufferText.innerText = obj.name[window.brief.lang];

			if (this.fieldsetData.length && _bufferInput) {

				if (this.fieldsetData.includes(obj.id)) {
					this.fieldsetCounter++;
					_bufferInput.checked = true;
				} else {
					_bufferInput.checked = false;
				}

			}

		});
	}

	toggleSelect(target) {
		const group = this.tergetImg.groupName; // str
		const url = this.tergetImg.src; // str
		const state = target.classList.contains('js-selected'); // bool

		if (state) {
			if (brief.selectedImages[group] && brief.selectedImages[group].includes(url)) {
				let i = brief.selectedImages[group].findIndex(x => x == url);
				if (i >= 0) brief.selectedImages[group].splice(i, 1);
				brief.imageComments[group][url] = '';
				// new
				target.classList.remove('js-has-comment');
			}

			target.classList.remove('js-selected');

		} else {
			if (!brief.selectedImages[group]) brief.selectedImages[group] = [];
			if (!brief.selectedImages[group].includes(url)) brief.selectedImages[group].push(url);

			// еще один класс для комментария
			if (!brief.imageComments[group][url]) {
				brief.imageComments[group][url] = '';
				target.classList.remove('js-has-comment');
			// new
			} else {
				target.classList.add('js-has-comment');
			}

			target.classList.add('js-selected');
		}

		brief.save();
	}

}

module.exports = Modals;
