class DragAndDrop {
	constructor() {}

	bindInnerBtn() {
		const $btn = $('.drag-and-drop__btn');
		const $input = $('.drag-and-drop__input');
		const dd = this;

		$btn.on('click', function (ev) {
			if (brief.brandbookFile) {
				brief.brandbookFile = null;
				dd.update();
				brief.save();

				let req = new XMLHttpRequest();

				req.onreadystatechange = function() {
					if (req.readyState == XMLHttpRequest.DONE) {
						if (req.status != 200) {
							console.error(req.response);
						}
					}
				}

				req.open('POST', `${BASE_URI}/delete-brandbook`, true);
				req.send();
				$input.files = [];
			} else {
				$input.click();
			}
		});
	}

	init() {
		const $block = $('.drag-and-drop');
		const $form = $('.drag-and-drop__form');
		const $input = $('.drag-and-drop__input');
		const dd = this;

		if ($block.length) {

			$block.on('dragenter dragover', function (ev) {
				ev.preventDefault();
				$block.addClass('drag-over');
			});

			$block.on('dragleave drop', function (ev) {
				ev.preventDefault();
				$block.removeClass('drag-over');
			});

			$block.on('drop', function (ev) {
				const data = ev.originalEvent.dataTransfer;
				const files = data.files;

				handle(files);
			});

			

			$input.on('input', function (ev) {
				handle(this.files);
			});

			function handle(files) {
				let size = 0;
		
				files = [...files];
		
				files.forEach((file) => {
					size += file.size;
				});

				if (size < 50 * 1024 * 1024) {
					files.forEach(uploadFile);
				} else {
					return alert((files.length > 1 ? 'Загруженные файлы слишком велики.' : 'Загруженный файл слишком велик.') + ' Суммарный вес всех загруженных файлов не должен превышать 50 Мб.');
				}
			}

			function uploadFile(file) {
				let req = new XMLHttpRequest();
				let fd = new FormData();

				// Добавляем спиннер
				const dndForm = document.querySelector('.drag-and-drop');
				const divEl = document.createElement('div');
				divEl.className = 'spinner';
				divEl.innerHTML = `<divc class='spinner-border', role='status'><span class='visually-hidden'>Загрузка...</span>`;
				
				dndForm.appendChild(divEl);
				// Добавляем спиннер

				fd.append('file', file);

				req.onreadystatechange = function() {
					if (req.readyState == XMLHttpRequest.DONE) {
						if (req.status == 200) {
							brief.brandbookFile = file.name;
							dd.update();
							brief.save();

							$('.email-form__input[name="brandbook"]').val(brief.brandbook);
						} else {
							console.error(req.response);
						}

						// Удаляем спиннер
						divEl.remove();
					}
				}

				req.open('POST', `${BASE_URI}/upload-brandbook`, true);
				req.send(fd);
			}
		}
	}

	update() {
		/**
		 * Поле для ввода ссылки на брендбук компании.
		 * @type {HTMLInputElement}
		 */
		const $brandbookField = document.querySelector('[name="brandbook"]');

		/**
		 * Блок для загрузки файла брендбука компании.
		 * @type {HTMLElement}
		 */
		const $dragAndDrop = document.querySelector('.drag-and-drop');

		/**
		 * Блок с сообщением о загрузке брендбука.
		 * @type {HTMLParagraphElement}
		 */
		const $dragAndDropText = document.querySelector('.drag-and-drop p');

		if ($dragAndDrop && $dragAndDropText) {
			if (brief.brandbookFile) {
				brief.brandbook = '';
				brief.save();
				$brandbookField.value = '';
				$brandbookField.setAttribute('placeholder', brief.brandbookFile);
				$brandbookField.setAttribute('disabled', true);
				$dragAndDropText.innerHTML = `Вы прикрепили файл, для отмены нажмите <span class="drag-and-drop__btn">здесь</span>`;
			} else {
				$brandbookField.setAttribute('placeholder', 'Укажите ссылку на скачивание');
				$brandbookField.removeAttribute('disabled');
				$dragAndDropText.innerHTML = `Перетащите файл в эту область для загрузки или <span class="drag-and-drop__btn">нажмите сюда</span>, чтобы выбрать`;
			}

			this.bindInnerBtn();
		}
	}
}

module.exports = DragAndDrop;
