const { Color } = require('./color');
const { tones, comments, sections } = require('../../configs/brief.config');

/**
 * Класс, описывающий основные данные, хранимые о брифе.
 */
class BriefBase {
	constructor () {

		/**
		 * Электронные почты клиента.
		 * @type {[string]}
		 */
		this.emails = [];

		/**
		 * Ссылка на брендбук клиента.
		 * Необязательное поле. Стирается, если клиент загружает файл.
		 * @type {string}
		 */
		this.brandbook = '';

		/**
		 * Продукт. Например, AR, CSR.
		 * Необязательно к заполнению поле, может быть переписано менеджером.
		 * @type {string}
		 */
		this.product = '';

		/**
		 * Сегменты экономика компании клиента.
		 * @type {[string]}
		 */
		this.segments = [];

		/**
		 * Свой вариант сегмента экономики.
		 * @type {string}
		 */
		this.customSegment = '';

		/**
		 * Цветовая палитра будущего отчета.
		 */
		this.colors = {

			/**
			 * Основной цвет отчета.
			 * @type {?Color}
			 */
			main: null,

			/**
			 * Дополнительный цвет отчета.
			 * @type {?Color}
			 */
			additional: null
		}

		/**
		 * Разрешил ли клиент использование дополнительных цветов.
		 * В случае, если клиент еще ничего не выбрал является `null`.
		 * @type {?boolean}
		 */
		this.allowAdditionalColors = null;

		/**
		 * Тон отчета по разным параметрам.
		 * @type {[{left: string, right: string, max: number, value: number}]}
		 */
		this.tones = tones.map(x => {
			return {
				left: x.left,
				right: x.right,
				max: x.max,
				value: x.start
			}
		});

		/**
		 * Комментарии клиента по разным вопросам.
		 * @type {[{text: string, value: string}]}
		 */
		this.comments = comments.map(x => {
			return {
				text: x.text,
				value: ''
			}
		});

		/**
		 * Выбранные клиентом изображения.
		 * @type {Object.<string, string[]>}
		 */
		this.selectedImages = {};

		/**
		 * Выбранные клиентом изображения.
		 * @type {Object.<string, Object.<string, string>>}
		 */
		this.imageComments = {};

		const brief = this;
		sections.map(x => {
			brief.selectedImages[x.code] = [];
			brief.imageComments[x.code] = {};

			x.subsections.map(a => {
				brief.selectedImages[a.code] = [];
				brief.imageComments[a.code] = {};
			});
		});

		/**
		 * Разделитель языка из атрибута html
		 * @type {string}
		 */
		this.lang = '';
	}
}

module.exports = {
	BriefBase: BriefBase
}
