class ImageQuery {
	init() {
		const $queryButton = $('.image-query__get-button');
		const $queryGallery = $('.image-query__preview');
		const $filters = $('.image-query__checkbox input[type="checkbox"]');

		// brief.activeColorSelector = 'main';


		function sendQuery() {
			let tags = [];

			$filters.each(function(i, el) {
				if (el.checked) tags.push(el.id);
			});

			$.ajax({
				type: 'POST',
				url: `${BASE_URI}/api/images/query/tags`,
				data: {
					tags: tags,
					color: brief.colors.main
				},
				success: onSuccess,
				error: onError
			});

			function onSuccess(res) {
				if (res.urls) {
					res.urls.forEach(url => {
						let imgBlock = document.createElement('div');
						let img = document.createElement('img');

						$(imgBlock).addClass('image-query__image');
						$(imgBlock).attr('iid', url);
						imgBlock.appendChild(img);

						img.src = `${BASE_URI}/api/images/get/${url}`;

						$queryGallery.append(imgBlock);
					});
				}
			}

			function onError(err) {
				console.log(err);
			}
		}

		$queryButton.click(sendQuery);
	}
}

module.exports = ImageQuery;
