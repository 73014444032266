const KEY_A = 65;
const KEY_D = 68;

class ImageUpload {
	constructor() {}

	async init() {
		const $block = $('.image-upload__drop');
		const $form = $('.image-upload__drop-form');
		const $progress = $('.image-upload__drop-progress');
		const $checkboxes = $('.image-upload input[type="checkbox"]');
		const $gallery = $('.image-upload__list');
		const $preview =  $('.image-upload__preview');
		const $selectAllButton = $('.image-upload__control-all');
		const $resetSelectionButton = $('.image-upload__control-reset');
		const $sendButton = $('.image-upload__control-send');
		const $deleteButton = $('.image-upload__control-delete');
		const $message = $('.image-upload .message');
		var $uploaded = $('.image-upload__list-item');

		var filesToDo = 0; // Осталось обработать файлов.
		var filesDone = 0; // Файлов уже обработано.
		var tagsData = {}; // Хранит теги.
		var uploadedFiles = []; // Хранит новые загруженные файлы.
		var oldFiles = []; // Хранит имена файлов, которые уже были.
		var oldFilesСache = {}; // Хранит информацию о тегах на сервере.
		var fileDeletions = []; // Хранит удаления уже загруженных файлов.

		// Режим работы.
		var mode = {
			type: 'set',
			tags: {
				black: [], // Чистые теги.
				gray: [] // Смешанные теги.
			}
		};

		const editor = this;

		if ($block.length) {
			
			// Скрыть блок с сообщениями.
			$message.css('opacity', 0);

			// Отключение контекстного меню.
			window.oncontextmenu = function() {
				return false;
			}

			initFileHandler();
			initButtons();
			initCheckboxes();
			await initGallery();

			const url = new URL(window.location.href);
			const hightlightReq = url.searchParams.get('hightlight');

			if (hightlightReq && this.loadedImages[hightlightReq]) {
				let path = this.loadedImages[hightlightReq].path.replace('.', '-');
				const $target = $(`.image-upload__list-item[fname="${path}"]`);
				
				if ($target.length) $target.click();
			}
		}

		/**
		 * Инициализация drag and drop.
		 */
		function initFileHandler() {
			$progress.hide();

			$block.on('dragenter dragover', function (ev) {
				ev.preventDefault();
				$block.addClass('highlighted');
			});

			$block.on('dragleave drop', function (ev) {
				ev.preventDefault();
				$block.removeClass('highlighted');
			});

			$block.on('drop', function (ev) {
				const data = ev.originalEvent.dataTransfer;
				const files = data.files;

				handleFiles(files);
			});
		}

		/**
		 * Обработчик загрузки новых файлов.
		 * @param {[File]} files Загруженные файлы.
		 */
		function handleFiles(files) {
			files = [...files];
			initProgress(files.length);
			let selectNew = $('.selected').length == 0;
	
			files.forEach((file) => {
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = function() {
					tagsData[file.name] = [];
					createImage(reader.result, file.name, selectNew);
				}
			});

			uploadedFiles = uploadedFiles.concat(files);
			redrawImageTags();
			resetCheckboxes();
		}

		/**
		 * Инициализирует полосу загрузки.
		 * @param {Number} fileCount Количество файлов.
		 */
		function initProgress(fileCount) {
			$progress.show();
			$progress.val(0);
			filesToDo = fileCount;
			filesDone = 0;
		}

		/**
		 * Инициализирует обработчики для кнопок.
		 */
		function initButtons() {
			/**
			 * Кнопка выбрать все.
			 */
			$selectAllButton.click(function() {
				$uploaded.addClass('selected');
				calculateSelectedProperties();
			});

			/**
			 * Кнопка очистить выделение.
			 */
			$resetSelectionButton.click(function() {
				$uploaded.removeClass('selected');
				calculateSelectedProperties();
			});

			/**
			 * Кнопка синхронизации с сервером.
			 */
			$sendButton.click(function() {
				syncCommit();
			});

			/**
			 * Кнопка удаления файлов.
			 */
			$deleteButton.click(function() {
				let selected = $('.selected');

				selected.each(function(i, el) {
					let fname = $(el).attr('fname');

					tagsData[fname] = [];

					console.log(oldFiles)

					let q1 = uploadedFiles.findIndex(x => x.name == fname);
					let q2 = oldFiles.findIndex(x => x == fname);

					if (q1 >= 0) {
						uploadedFiles.splice(q1, 1);
						$(el).remove();
					}

					if (q2 >= 0) {
						fileDeletions.push(fname);
						oldFiles.splice(q2, 1);
						console.log(32)
						$(el).remove();
					}
				});
			});

			/**
			 * Бинды горячих клавиш.
			 */
			$(window).keydown(function (ev) {
				if (ev.ctrlKey) {
					if (ev.preventDefault) {
						ev.preventDefault();
					} else { 
						ev.returnValue = false;
					}

					if (ev.keyCode == KEY_A) $selectAllButton.click();
					if (ev.keyCode == KEY_D) $resetSelectionButton.click();
				}
			});
		}

		/**
		 * Инициализирует обработчики для чекбоксов.
		 */
		function initCheckboxes() {
			$checkboxes.on('click', function (ev) {
				let selected = $('.selected');
				let cb = this;

				if (ev.button == 0) {
					selected.each(function(i, el) {
						setItemProperty(el, cb.checked, cb.id);
					});
	
					calculateSelectedProperties();
					redrawImageTags();
				}
			});

			$checkboxes.on('mouseup', function (ev) {
				let selected = $('.selected');
				let cb = this;

				if (ev.button == 2) {
					let queue = [];

					selected.removeClass('selected');
					
					for (let fname in tagsData) {
						if (tagsData[fname].includes(cb.id)) {
							queue.push(fname);
						}
					}

					queue.forEach((fname) => {
						$(`.image-upload__list-item[fname="${fname}"]`).addClass('selected');
					});

					calculateSelectedProperties();
					redrawImageTags();
				}
			});
		}

		/**
		 * Инициализирует галлерею.
		 */
		async function initGallery() {
			let cid = window.location.toString().match(/image-commit\/([0-9a-zA-Z]{24})/);

			cid = cid? cid[1] : null;

			if (cid) {
				let commitData = await getCommitData(cid);
				editor.commit = commitData;

				$('.image-upload__commit-id').text(`CID: ${commitData._id}`);
				$('.image-upload__commit-name').val(commitData.name? commitData.name : '');

				let imagesLoaded = 0;
				editor.loadedImages = {};
				for (let i = 0; i < commitData.images.length; i++) {
					const iid = commitData.images[i];
					let imageData = await getCommitImageData(iid);
					editor.loadedImages[iid] = imageData;
					
					if (++imagesLoaded == commitData.images.length) {
						fillCommitImages();
					}
				}
			}
		}

		/**
		 * Получает данные о комите.
		 * @param {String} cid ID комита.
		 */
		function getCommitData(cid) {
			return new Promise((resolve, reject) => {
				let req = new XMLHttpRequest();

				req.onreadystatechange = function() {
					if (req.readyState == XMLHttpRequest.DONE) {
						if (req.status === 200) {
							let res = JSON.parse(req.response);
							return resolve(res);
						}

						reject();
					}
				}

				req.open('GET', `${BASE_URI}/api/image-commit/${cid}/get`, true);
				req.send();
			});
		}

		/**
		 * Заполняет галлерею изображениями с сервера.
		 */
		function fillCommitImages() {
			editor.commit.images.forEach((iid) => {
				let url = editor.loadedImages[iid].path.replace('.', '-');
				createImage(`${BASE_URI}/api/images/get/${url}`, url);
				tagsData[url] = editor.loadedImages[iid].tags;
				oldFiles.push(url);
				oldFilesСache[url] = [...editor.loadedImages[iid].tags];
			});

			redrawImageTags();
		}

		/**
		 * Получает информацию о тегах изображения.
		 * @param {String} iid ID изображения.
		 */
		function getCommitImageData(iid) {
			return new Promise((resolve, reject) => {
				let req = new XMLHttpRequest();

				req.onreadystatechange = function() {
					if (req.readyState == XMLHttpRequest.DONE) {
						if (req.status === 200) {
							let res = JSON.parse(req.response);
							return resolve(res);
						}

						reject(null);
					}
				}
	
				req.open('GET', `${BASE_URI}/api/brief-image/${iid}/get`, true);
				req.send();
			});
		}
		
		/**
		 * Загружает изображение в указанный комит.
		 * @param {String} cid ID комита.
		 * @param {File} file Файл изображения.
		 * @param {[String]} tags Теги изображения.
		 * @param {Function} cb Функция обратного вызова.
		 */
		function uploadImageToCommit(cid, file, tags, cb) {
			let fd = new FormData();

			fd.append('file', file);
			fd.append('tags', JSON.stringify(tags));

			fetch(`/api/image-commit/${cid}/append`, {
				method: 'POST',
				body: fd
			})
				.then(() => cb())
				.catch((err) => { alert('Не удалось загрузить файлы.');console.log(err)} );
		}

		/**
		 * Синхронизирует информацию о комите (не изображения).
		 */
		function sendCommitData() {
			return new Promise((resolve, reject) => {
				let req = new XMLHttpRequest();

				req.onreadystatechange = function() {
					if (req.readyState == XMLHttpRequest.DONE) {
						if (req.status === 200) {
							return resolve();
						}

						reject();
					}
				}
	
				req.open('POST', `${BASE_URI}/api/image-commit/${editor.commit._id}/data`, true);
				req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
				req.send(JSON.stringify({
					name: $('.image-upload__commit-name').val()
				}));
			});
		}

		/**
		 * Отправляет изменения на сервер (изображения, но не загрузка новых).
		 */
		function sendUpdates() {
			let actions = [];

			oldFiles.forEach(fname => {
				actions.push({
					type: 'update',
					fname: fname,
					tags: tagsData[fname]
				});
			});

			fileDeletions.forEach(fname => {
				actions.push({
					type: 'delete',
					fname: fname
				});
			});

			return new Promise((resolve, reject) => {
				let req = new XMLHttpRequest();

				req.onreadystatechange = function() {
					if (req.readyState == XMLHttpRequest.DONE) {
						if (req.status === 200) {
							return resolve();
						}

						reject();
					}
				}
	
				req.open('POST', `${BASE_URI}/api/image-commit/${editor.commit._id}/update`, true);
				req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
				req.send(JSON.stringify({
					actions: actions
				}));
			});
		}

		async function syncCommit() {
			await sendCommitData();
			await sendUpdates();

			let uploadedFilesCount = 0;
			
			uploadedFiles.forEach((file) => {
				uploadImageToCommit(editor.commit._id, file, tagsData[file.name], () => checkFinished());
			});

			if (uploadedFiles.length == 0) document.location.reload();

			function checkFinished() {
				if (++uploadedFilesCount == uploadedFiles.length || uploadedFiles.length == 0) {
					document.location.reload();
				}
			}
		}

		/**
		 * Отображает маркировки на изображениях.
		 */
		function redrawImageTags() {
			let $loadedImages = $('.image-upload__list-item');

			$loadedImages.each(function (i, el) {
				let d = getItemProperties(el);

				if (d.length == 0) {
					$(el).addClass('has-no-tags');
				} else {
					$(el).removeClass('has-no-tags');
				}

				let fname = $(el).attr('fname');

				if (oldFilesСache[fname]) {
					if (compareArrays(d, oldFilesСache[fname]).difs.length == 0) {
						$(el).removeClass('updated-tags');
					} else {
						$(el).addClass('updated-tags');
					}
				}
			});
		}

		/**
		 * Добавляет в галлерею новое изображение.
		 * @param {String} src URL до изображения.
		 * @param {String} name Имя изображения.
		 * @param {Boolean} selectNew Выбрать после создания?
		 */
		function createImage(src, name, selectNew = false) {
			let imgBlock = document.createElement('div');
			let img = document.createElement('img');

			$(imgBlock).addClass('image-upload__list-item');
			$(imgBlock).attr('fname', name);

			if (selectNew) $(imgBlock).addClass('selected');

			imgBlock.appendChild(img);
			$gallery.append(imgBlock);
			img.src = src;
			updateGallery();
		}

		/**
		 * Обнорвляет шкалу загрузки.
		 */
		function updateProgress() {
			filesDone++;
			$progress.val(filesToDo > 0? filesDone / filesToDo * 100 : 100);

			if (filesDone == filesToDo) {
				$progress.hide();
			}
		}

		/**
		 * Возвращает выбранные на данный момент теги.
		 */
		function parseCheckboxes() {
			var tags = [];

			$checkboxes.each(function (i, el) {
				if (el.checked) {
					tags.push(el.id);
				}
			});

			return tags;
		}

		/**
		 * Добавляет обработчики на все изображения.
		 */
		function updateGallery() {
			$uploaded = $('.image-upload__list-item');

			$uploaded.unbind();

			$uploaded.click(function (ev) {
				$(this).toggleClass('selected');
				setPreview($(this).find('img')[0].src);
				calculateSelectedProperties();
			});
		}

		/**
		 * Обновляет превью.
		 * @param {String} src Новый URl для отображения.
		 */
		function setPreview(src) {
			$preview.css('background', `url(${src})`);
			$preview.css('background-repeat', 'no-repeat');
			$preview.css('background-size', 'cover');
			$preview.css('background-position', 'center');
		}

		/**
		 * Сбрасывает все чекбоксы.
		 */
		function resetCheckboxes() {
			$checkboxes.each(function (i, el) {
				el.checked = false;
				el.disabled = false;
			});
		}

		/**
		 * Возвращает теги элемента.
		 * @param {HTMLElement} el HTML-эелемент.
		 */
		function getItemProperties(el) {
			var imageName = $(el).attr('fname');

			return tagsData[imageName];
		}

		/**
		 * Устанавливает теги для элемента.
		 * @param {HTMLElement} el HTML-эелемент.
		 * @param {Boolean} state Состояние.
		 * @param {String} prop Название тега.
		 */
		function setItemProperty(el, state, prop) {
			var imageName = $(el).attr('fname');

			if (!state) {
				let idx = tagsData[imageName].findIndex(x => x == prop);
				if (idx >= 0) {
					tagsData[imageName].splice(idx, 1)
				}
			} else {
				if (!tagsData[imageName].includes(prop)) {
					tagsData[imageName].push(prop);
					tagsData[imageName].sort();
				}
			}
		}

		/**
		 * Сравнивает элементы в массиваз и выдает различия и схождения.
		 * @param {[String]} arr1 Первый массив.
		 * @param {[String]} arr2 Второй массив.
		 */
		function compareArrays(arr1, arr2) {
			let s = arr1.length > arr2.length? 2 : 1;
			let l = s == 1? 2: 1;
			let difs = [];
			let same = [];
			let arr = {
				1: arr1,
				2: arr2
			};

			arr[1].sort();
			arr[2].sort();

			for (let i = 0; i < arr[l].length; i++) {
				let el = arr[l][i];

				if (arr[s].includes(el)) {
					same.push(el);
				} else {
					difs.push(el);
				}
			}

			for (let i = 0; i < arr[s].length; i++) {
				let el = arr[s][i];

				if (!same.includes(el)) {
					difs.push(el);
				}
			}

			return {
				difs: difs,
				same: same
			};
		}

		/**
		 * Возвращает новый массив, содержащий элементы исходных. Повторяющиеся элементы в новом массиве отсутсвуют.
		 * @param {[String]} arr1 Первый массив.
		 * @param {[String]} arr2 Второй массив.
		 */
		function mergeArrays(arr1, arr2) {
			let result = [];

			for (let i = 0; i < arr1.length; i++) {
				if (!result.includes(arr1[i])) result.push(arr1[i]);
			}

			for (let i = 0; i < arr2.length; i++) {
				if (!result.includes(arr2[i])) result.push(arr2[i]);
			}

			return result;
		}

		/**
		 * Отображает теги выбранных элементов на чекбоксах.
		 */
		function calculateSelectedProperties() {
			var selected = $('.selected');
			var selection = [];
			var difs = [];

			resetCheckboxes();

			selected.each(function(i, el) {
				if (i == 0) {
					selection = getItemProperties(el);
				} else {
					let cmp = compareArrays(getItemProperties(el), selection)
					selection = cmp.same;
					difs = mergeArrays(difs, cmp.difs);
				}
			});

			$checkboxes.parent('.image-upload__checkbox').removeClass('image-upload__checkbox--gray');

			$checkboxes.each(function (i, el) {
				if (selection.includes(el.id)) el.checked = true;
				if (difs.includes(el.id)) $(el.parentElement).addClass('image-upload__checkbox--gray');
			});

			if (difs.length > 0) {
				$message.css('opacity', 1);
				mode.type = 'add';
				mode.tags = {
					black: selection,
					gray: difs
				}
			} else {
				$message.css('opacity', 0);
				mode.type  = 'set';
				mode.tags = {
					black: selection,
					gray: difs
				}
			}
		}
	}
}

module.exports = ImageUpload;
