class Preloader {
	constructor() {
		this.$preloader = $('#preloaderBase');
		this.enabled = true;
		this.$body = document.querySelector('body:not(.body--management)');

		if (this.$body && this.$preloader.length) {
			this.$body.style.overflow = 'hidden';
		} else {
			this.enabled = false;
		}
	}

	disablePreloader() {
		this.enabled = false;

		if (!(this.$preloader.length && this.$body)) {
			return false;
		}

		this.$body.style.overflow = 'visible';
		this.$body.classList.remove('body--final-loader');

		this.$preloader.fadeOut();
		brief.checkValidContinue();
		brief.checkValidReturn();
	}
}

module.exports = Preloader;
