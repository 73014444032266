function isValidEmail(str) {
	let reg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
	return str.match(reg)? true : false;
}


module.exports = {
	structure: [
		{
			type: 'static',
			fname: 'brief/blocks/first_appeal.pug',
			group: 0,
			test: (brief) => {
				return true;
			}
		},
		{
			type: 'static',
			fname: 'brief/blocks/second_comments.pug',
			showSidebar: true,
			sidebarTime: 7000,
			group: 0,
			test: (brief) => {
				return true;
			}
		},
		{
			type: 'static',
			fname: 'brief/blocks/third_range.pug',
			showSidebar: true,
			sidebarTime: 7000,
			group: 0,
			test: (brief) => {
				return true;
			}
		},

		{
			/** Верстка
			 * Классическая галерея с модалкой
			 * section-gallery.pug
			 * include /components/gallery/template.pug */
			type: 'gallery--fourth',
			showSidebar: true,
			sidebarTime: 5000,
			group: 1,
			section: 0,
			test: (brief) => {
				return true;
			}
		},
		{
			/** Фотографии
			 * Галерея с табами без модалки
			 * template--tabs.pug */
			type: 'gallery--fifth',
			group: 1,
			section: 1,
			test: (brief) => {
				return true;
			}
		},
		{
			/** Иллюстрации
			 * Галерея с табами и модалками?
			 * template--tabs.pug */
			type: 'gallery--sixth',
			group: 1,
			section: 2,
			skipInDemo: true,
			test: (brief) => {
				return true;
			}
		},
		{
			/** Типографика
			 * Классическая галерея с модалкой
			 * section-gallery.pug
			 * include /components/gallery/template.pug */
			type: 'gallery--seventh',
			group: 1,
			section: 3,
			skipInDemo: true,
			test: (brief) => {
				return true;
			}
		},
		{
			/** Инфографика
			 * Галерея с табами и модалками?
			 * template--tabs.pug
			 *
			 * TODO: расширить sections.config
			 * */
			type: 'gallery--eighth',
			group: 1,
			section: 4,
			skipInDemo: true,
			test: (brief) => {
				return true;
			}
		},
		{
			type: 'static',
			fname: 'brief/blocks/ninth_refs.pug',
			showSidebar: true,
			group: 1,
			section: 0,
			test: (brief) => {
				return true;
			}
		},
		{
			type: 'static',
			group: 2,
			fname: 'brief/blocks/results.pug',
			test: (brief) => {
				return true;
			}
		}
	]
}
