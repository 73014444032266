const { structure } = require('../../../configs/brief.config');

/**
 * Устанавливает привязки ко всем событиям.
 */
function bindEventsAndInitValues() {
	/**
	 * Поле для ввода ссылки на брендбук компании.
	 * @type {HTMLInputElement}
	 */
	const $brandbookField = document.querySelector('[name="brandbook"]');
	// Замена для принт-версии
	const $_print_brandbookField = document.querySelector('.js-print [name="brandbook"]');

	/**
	 * Если клиенту доступна эта опция, кнопки для выбора продукта.
	 * @type {HTMLInputElement[]}
	 */
	const $productFields = Array.from(document.querySelectorAll('[name="product"]'));

	/**
	 * Кнопки (чекбоксы) выбора профиля компании (сегмента экономики).
	 * @type {HTMLInputElement[]}
	 */
	const $segmentFields = Array.from(document.querySelectorAll('[name="profile_segment"]'));


	/**
	 * Кнопки выбора профиля компании (сегмента экономики).
	 * @type {HTMLElement[]}
	 */
	const $segmentButtons = Array.from(document.querySelectorAll('.profile-btn'));

	/**
	 * Кнопка выбора другого сегмента.
	 * @type {HTMLInputElement}
	 */
	const $otherSegmentField = document.querySelector('.profile__other-input');

	/**
	 * Кнопки выбора профиля компании (сегмента экономики).
	 * @type {HTMLTextAreaElement}
	 */
	const $otherSegmentText = document.querySelector('.profile__other textarea');

	/**
	 * Поля для отображения выбранных основного и дополнительного цвета.
	 * Так же переключает компонент `ColorPicker` на выбор соответствующего цвета.
	 * @type {HTMLInputElement[]}
	 */
	const $colorFields = Array.from(document.querySelectorAll('[name="color"]'));

	/**
	 * Переключатель выбора: можно ли использовать дополнительные цвета (отличные от брендбука) в отчете.
	 * @type {HTMLInputElement[]}
	 */
	const $allowAdditionalColorsFields = Array.from(document.querySelectorAll('[name="colors_allow_additional"]'));

	/**
	 * Ввод тонов отчета. Например, Яркий <--> Темный.
	 * @type {HTMLInputElement[]}
	 */
	// const $toneFields = Array.from(document.querySelectorAll('[name="tone"]'));
	const $toneFields = Array.from(document.querySelectorAll('.js-range-form'));

	/**
	 * Поля для комментариев об отчете.
	 * @type {HTMLInputElement[]}
	 * second_comments
	 * textarea.form-area__textarea(maxlength ="500" name="comment" data-index="0" placeholder="Напишите ваш комментарий")
	 */
	const $commentFields = Array.from(document.querySelectorAll('[name="comment"]'));

	/**
	 * Поля для комментариев об отчете.
	 * @type {HTMLInputElement[]}
	 * second_comments
	 * textarea.form-area__textarea(maxlength ="500", name="customRefsComment", placeholder="Напишите ваши комментарии здесь")
	 */
	const $commentRefs = document.querySelector('[name="customRefsComment"]');

	/**
	 * Кнопка далее.
	 * @type {HTMLButtonElement}
	 */
	const $continueButton = document.querySelector('#continue');

	/**
	 * Кнопка далее.
	 * @type {HTMLButtonElement}
	 */
	const $customRefsSkipBtn = document.querySelector('#customRefsSkip');

	/**
	 * Кнопка назад.
	 * @type {HTMLButtonElement}
	 */
	const $returnButton = document.querySelector('#return');

	/**
	 * Кнопка завершения брифа.
	 * @type {HTMLButtonElement}
	 */
	const $sendButton = document.querySelector('#send');

	/**
	 * Кнопка возврата на какой-либо шаг.
	 * @type {HTMLButtonElement[]}
	 */
	const $editImagesButtons = Array.from(document.querySelectorAll('.js-add-btn'));

	/**
	 * Фрагменты прогрессбара.
	 * @type {HTMLElement[]}
	 */
	const $progressBarItems = Array.from(document.querySelectorAll('.progressbar__item'));

	/**
	 * Бриф для изменения текста кнопки на слайдах
	 */
	const $brief = document.querySelector(".brief");

	if ($brief) {
		const slideNumber = Number($brief.getAttribute("data-slide"));

		if (slideNumber === 1) {
			$continueButton.textContent = window.brief.lang === "en" ? "Start" : "Начнем";
		} else if (slideNumber === 9) {
			$continueButton.textContent = window.brief.lang === "en" ? "Finish" : "Завершаем";
		}
	}

	if ($progressBarItems.length) {
		const upTo = brief.latestPage? brief.latestPage : 11;
		const activeGroup = structure[upTo - 1]['group'];

		$progressBarItems.map(x => {
			x.classList.remove('is-visited', 'is-current');
		});

		for (let i = 1; i <= upTo; i++) {
			const $progressBarItem = document.querySelector(`.progressbar__item--${i}`);

			if ($progressBarItem) {
				$progressBarItem.classList.add('is-visited');

				if (i == brief.activePage) {
					$progressBarItem.classList.add('is-current');
				}

				if (structure[i - 1]['group'] == activeGroup) {
					$progressBarItem.classList.add('is-active-group');
					$progressBarItem.addEventListener('click', onClick(i));
				}
			}
		}

		function onClick (i) {
			return function (ev) {
				cookies.set('p', i);
				brief.activePage = i;
				brief.save();
				location.reload();
			}
		}
	}

	if ($brandbookField) {
		$brandbookField.value = brief.brandbook;
		$brandbookField.addEventListener('input', onChange);

		function onChange (ev) {
			brief.brandbook = this.value;
			brief.save();
		}

		components['DragAndDrop'].update();
	}

	if ($_print_brandbookField) {
		let brandbookState = 'Не указан.';
		if (brief.brandbookFile) {
			brandbookState = `Вы загрузили файл: ${brief.brandbookFile}`;
		} else if (brief.brandbook && brief.brandbook.trim().length) {
			brandbookState = brief.brandbook.search(/^HTTP|HTTPS|http(s)?:\/\/(www\.)?[A-Za-z0-9]+([\-\.]{1}[A-Za-z0-9]+)*\.[A-Za-z]{2,40}(:[0-9]{1,40})?(\/.*)?$/) >=0 ? 'Была приложена ссылка для скачивания' : `Ваш комментарий: ${brief.brandbook.trim()}`;
		}

		$_print_brandbookField.value = brandbookState;
	}

	/** if ($productFields.length) {
		$productFields.map(x => {
			x.checked = x.value == brief.product;
			x.addEventListener('change', onChange);
		});

		function onChange (ev) {
			if (this.checked) {
				brief.product = this.value;
				brief.save();
				brief.checkValidContinue();
			}
		}
	} */

	if ($segmentFields.length) {
		$segmentFields.map(x => {
			x.checked = brief.segments.includes(x.value);
			x.addEventListener('change', onChange);
		});

		$segmentButtons.map(x => {
			x.addEventListener('click', onClick);
		});

		checkForDisabled();

		function checkForDisabled() {
			$segmentFields.map(x => {
				x.disabled = brief.customSegment? true :
					brief.segments.length > 3? !x.checked : false;
			});
		}

		function onChange (ev) {
			const i = brief.segments.findIndex(x => x == this.value);

			if (this.checked && i == -1) {
				brief.segments.push(this.value);
				brief.segments.sort();
			}

			if (!this.checked && i >= 0) {
				brief.segments.splice(i, 1);
			}

			checkForDisabled();
			brief.save();
			brief.checkValidContinue();
		}

		function onClick (ev) {
			if ($otherSegmentField && $otherSegmentText) {
				if ($otherSegmentField.checked) {
					$otherSegmentField.checked = false;
					$otherSegmentText.value = '';
					brief.customSegment = '';
					brief.save();

					$segmentFields.map(x => {
						x.checked = false;
						x.disabled = false;
					});
				}
			}
		}
	}

	if ($otherSegmentField && $otherSegmentText) {
		$otherSegmentField.addEventListener('click', onTextAreaActive);
		$otherSegmentText.addEventListener('click', onTextAreaActive);
		$otherSegmentText.addEventListener('focus', onTextAreaActive);
		$otherSegmentText.addEventListener('input', onTextAreaChange);

		if (brief.customSegment) {
			$otherSegmentText.value = brief.customSegment;
			$otherSegmentField.checked = true;
		}

		function onTextAreaActive (ev) {
			$otherSegmentField.checked = true;

			$segmentFields.map(x => {
				x.checked = false;
				x.disabled = true;
			});

			brief.segments = [];
			brief.checkValidContinue();
			brief.save();
		}

		function onTextAreaChange (ev) {
			brief.customSegment = this.value;
			brief.save();

			onTextAreaActive();
		}
	}

	if ($colorFields.length) {
		$colorFields.map(x => {
			x.addEventListener('change', onChange);
		});

		if (!brief.colors.main) {

			/**
			 * Карточка с дополнительным цветом.
			 * @type {HTMLElement}
			 */
			const additionalColorCard = document.querySelector('.form-colors__card--additional');
			additionalColorCard.style.display = 'none';
		}

		function onChange (ev) {
			if (this.checked && components['ColorPick']) {
				components['ColorPick'].activeColorSelector = this.value;
				components['ColorPick'].update();
			}
		}
	}

	if ($allowAdditionalColorsFields.length) {

		const allowMap = {
			'yes': true,
			'no': false
		}

		$allowAdditionalColorsFields.map(x => {
			x.checked = brief.allowAdditionalColors == allowMap[x.value];
			x.addEventListener('change', onChange);
		});

		function onChange (ev) {
			if (this.checked) {
				brief.allowAdditionalColors = this.value == "yes";
				brief.checkValidContinue();
				brief.save();
			}
		}
	}

	if ($toneFields.length) {
		$toneFields.forEach(form => {
			const i = form.getAttribute('data-index');
			form.elements['tone'].value = brief.tones[i].value;
			form.addEventListener('change', onChange);
		});

		function onChange (ev) {
			const i = this.getAttribute('data-index');
			brief.tones[i].value = this.elements['tone'].value;
			brief.save();
		}
	}

	if ($commentFields.length) {
		$commentFields.map(x => {
			const i = x.getAttribute('data-index');
			x.value = brief.comments[i].value;
			x.addEventListener('change', onChange);
		});

		function onChange (ev) {
			const i = this.getAttribute('data-index');
			brief.comments[i].value = brief._sanitize(this.value);
			brief.save();
		}
	}

	if ($commentRefs) {
		$commentRefs.value = brief.customRefsComment ? brief.customRefsComment : '';
		$commentRefs.addEventListener('change', onChange);

		function onChange (ev) {
			brief.customRefsComment = brief._sanitize(this.value);
			brief.save();
		}
	}

	if ($customRefsSkipBtn) {
		$customRefsSkipBtn.addEventListener('click', onClick);

		function onClick (ev) {
			brief.gotoNext();
		}
	}

	if ($continueButton) {
		$continueButton.addEventListener('click', onClick);

		if (brief.finished) {
			$continueButton.innerText = window.brief.lang === "en" ? "Back" : "Вернуться";
		}

		function onClick (ev) {
			if (!this.classList.contains('btn--disabled')
				&& !this.classList.add('js-status-pending')) {

				cookies.rm('anchor');
				if (brief.checkNoImageSelected()) {
					const noImageModal = document.querySelector('.js-modal--no-image');

					if (noImageModal) {
						noImageModal.classList.add('is-active');
					}
				} else {
					this.classList.add('js-status-pending');

					brief.gotoNext(() => {
						this.classList.remove('js-status-pending');
					});
				}
			}
		}
	}

	if ($returnButton) {

		$returnButton.addEventListener('click', onClick);

		if (brief.finished || brief.activePage == 1) {
			$returnButton.style.opacity = '0';
		}

		function onClick (ev) {
			if (!this.classList.contains('btn--disabled')) {
				brief.gotoPrevious();
			}
		}
	}

	if ($editImagesButtons.length) {
		$editImagesButtons.map(x => x.addEventListener('click', onClick));


		function onClick (ev) {
			let sectionId = this.getAttribute('data-sid');
			let _slideId = this.getAttribute('data-slide-id');

			const anchor = this.getAttribute('data-anchor-tab-id');

			if (_slideId) {
				cookies.set('p', _slideId);
				location.reload();
			} else {
				brief.sync((res) => {
					let slideId = structure.findIndex(x => x.section == sectionId) + 1;

					if (slideId > 0) {
						cookies.set('p', slideId);

						/** Additional fn
						 * tabs anchor
						*/
						if (anchor) {
							cookies.set('anchor', JSON.stringify({slide: slideId, target: anchor}));
						}

						location.reload();
					}

				}, (err) => console.error(err));
			}
		}
	}

	if ($sendButton) {
		$sendButton.addEventListener('click', onClick);

		function onClick (ev) {
			if (!this.classList.contains('btn--disabled')) {
				const elements = brief.showEndModal();

				brief.close((res) => {
					res = JSON.parse(res);
					
					if (res.isDemo) {
						localStorage.clear();
						window.components['CustomReferenceController'].db.close();
						indexedDB.deleteDatabase('client-refs');
						window.cookies.rm('u');
						window.cookies.rm('uid');
						window.cookies.rm('p');

						brief.updateEndModal(res, elements);
						setTimeout(() => {
							location.reload()
						}, 10000);

						return;
					}

					brief.updateEndModal(res, elements);
				}, (err) => { // [TODO]
					try {
						err = JSON.parse(err);
					} catch (error) {
						console.log('is not a valid JSON', err);
						return false;
					}

					if (err.pdf) {
						brief.updateEndModal(err, elements);
					}
				});
			}
		}
	}
}

module.exports = {
	bindEventsAndInitValues: bindEventsAndInitValues
}
