window.$ = require('jquery/dist/jquery.min.js');
require("./vendor/poly.js");
// var _regenerator = require("@babel/runtime/regenerator"); old babel

window.BASE_URI = window.location.pathname.startsWith('/brief') ? '/brief' : '';

if (!window.initiated) {

	window.Core = sessionStorage['Core']? window.Core = JSON.parse(sessionStorage['Core']) : {
		commits: {
			currentID: '',
			currentSS: []
		}
	};

	window.Core.save = function () {
		sessionStorage['Core'] = JSON.stringify(window.Core);
	}

	window.initiated = true;
}

// Очистка кэша брифа в меню
if (document.body.classList.contains('body--management-menu') && localStorage.brief) {
	localStorage.removeItem('brief')
}

window.components = [];

const DragAndDrop = require("../components/drag-and-drop/index.js");
window.components['DragAndDrop'] = new DragAndDrop();

let userToken = window.cookies.get('u');
let isBrief = !(userToken && userToken.startsWith('APL'));

const Brief = require("../components/brief/index.js");
window.brief = new Brief();

window.components['DragAndDrop'].init();

const Preloader = require("../components/preloader/index.js");
window.components['Preloader'] = new Preloader();

const Modals = require("../components/modals/index.js");
window.components['Modals'] = new Modals();
window.components['Modals'].init();

if (isBrief) {
	const CustomReferenceController = require("../components/custom-reference/index.js");
	window.components['CustomReferenceController'] = new CustomReferenceController();
	window.components['CustomReferenceController'].init();

	const Gallery = require("../components/gallery/index.js");
	window.components['Gallery'] = new Gallery();
	window.components['Gallery'].init();

	window.brief.init();
}

const ImageUpload = require("../components/image-upload/index.js");
window.components['ImageUpload'] = new ImageUpload();
window.components['ImageUpload'].init();
/**
const ColorPick = require("../components/color-pick/index.js");
window.components['ColorPick'] = new ColorPick();
window.components['ColorPick'] .init();
 */
const ImageQuery = require("../components/image-query/index.js");
window.components['ImageQuery'] = new ImageQuery();
window.components['ImageQuery'].init();

const EmailForm = require("../components/email-form/index.js");
window.components['EmailForm'] = new EmailForm();
window.components['EmailForm'].init();

const viewport = require("../components/layout/index.js");
