const segments = require('./brief/segments.json');
const colors = require('./brief/colors.json');
const tones = require('./brief/tones.json');
const comments = require('./brief/comments.json');
const sections = require('./brief/sections.json');
const { structure } = require('./brief/structure');

const SLIDE_TYPE = {
	STATIC: 'static',
	GALLERY: 'gallery',
	GALLERY_CB: 'gallery--checkbox',
	GALLERY_T: 'gallery--tabs'
}

module.exports = {
	segments: segments,
	colors: colors,
	tones: tones,
	comments: comments,
	sections: sections,
	structure: structure,
	SLIDE_TYPE: SLIDE_TYPE
}
