if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, 'includes', {
	  value: function(searchElement, fromIndex) {

		if (this == null) {
		  throw new TypeError('"this" is null or not defined');
		}

		// 1. Let O be ? ToObject(this value).
		var o = Object(this);

		// 2. Let len be ? ToLength(? Get(O, "length")).
		var len = o.length >>> 0;

		// 3. If len is 0, return false.
		if (len === 0) {
		  return false;
		}

		// 4. Let n be ? ToInteger(fromIndex).
		//    (If fromIndex is undefined, this step produces the value 0.)
		var n = fromIndex | 0;

		// 5. If n ≥ 0, then
		//  a. Let k be n.
		// 6. Else n < 0,
		//  a. Let k be len + n.
		//  b. If k < 0, let k be 0.
		var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

		function sameValueZero(x, y) {
		  return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
		}

		// 7. Repeat, while k < len
		while (k < len) {
		  // a. Let elementK be the result of ? Get(O, ! ToString(k)).
		  // b. If SameValueZero(searchElement, elementK) is true, return true.
		  if (sameValueZero(o[k], searchElement)) {
			return true;
		  }
		  // c. Increase k by 1.
		  k++;
		}

		// 8. Return false
		return false;
	  }
	});
}

if (!Array.prototype.find) {
	Object.defineProperty(Array.prototype, 'find', {
	  value: function(predicate) {
	   // 1. Let O be ? ToObject(this value).
		if (this == null) {
		  throw new TypeError('"this" is null or not defined');
		}

		var o = Object(this);

		// 2. Let len be ? ToLength(? Get(O, "length")).
		var len = o.length >>> 0;

		// 3. If IsCallable(predicate) is false, throw a TypeError exception.
		if (typeof predicate !== 'function') {
		  throw new TypeError('predicate must be a function');
		}

		// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
		var thisArg = arguments[1];

		// 5. Let k be 0.
		var k = 0;

		// 6. Repeat, while k < len
		while (k < len) {
		  // a. Let Pk be ! ToString(k).
		  // b. Let kValue be ? Get(O, Pk).
		  // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
		  // d. If testResult is true, return kValue.
		  var kValue = o[k];
		  if (predicate.call(thisArg, kValue, k, o)) {
			return kValue;
		  }
		  // e. Increase k by 1.
		  k++;
		}

		// 7. Return undefined.
		return undefined;
	  },
	  configurable: true,
	  writable: true
	});
}

(function (arr) {
	arr.forEach(function (item) {
	  if (item.hasOwnProperty('append')) {
		return;
	  }
	  Object.defineProperty(item, 'append', {
		configurable: true,
		enumerable: true,
		writable: true,
		value: function append() {
		  var argArr = Array.prototype.slice.call(arguments),
			docFrag = document.createDocumentFragment();

		  argArr.forEach(function (argItem) {
			var isNode = argItem instanceof Node;
			docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
		  });

		  this.appendChild(docFrag);
		}
	  });
	});
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);
