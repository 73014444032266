const Masonry = require('masonry-layout/masonry.js');
const imageCompression = require('browser-image-compression').default;

const CLIENT_REFS_V = 1;
const nullHandler = (err) => console.error(err);

class UserFile {
	constructor(fname, blob, size, comment = '') {
		this.name = fname;
		this.data = blob;
		this.size = size;
		this.comment = comment;
	}
}

class CustomReferenceController {
	constructor() {
		this.db = null;
		this.block = null;
		this.gallery = null;
		this.files = [];
		this.masonry = null;
		this.ready = false;
		this.onreadyCallbacks = [];
	}

	setOnReadyCallback(cb) {
		if (this.ready) cb();
		else this.onreadyCallbacks.push(cb);
	}

	async init() {
		this.block = document.querySelector('.references');

		await this.openIDB();

		if (this.block) {
			this.gallery = this.block.querySelector('.js-custom-gallery');
			this.bindInputs();

			this.masonry = new Masonry(this.gallery, {
				itemSelector: '.js-custom-block',
				columnWidth: '.grid-sizer',
				percentPosition: true,
				gutter: 10,
				initLayout: true,
				horizontalOrder: true
			});
		}

		let storedFiles = await this.readFilesFromIDB().catch(nullHandler);
		for (let i = 0; i < storedFiles.length; i++) {
			const file = storedFiles[i];
			this.files.push(file);
			if (this.block) await this.addGalleryItem(file);
		}

		this.ready = true;
		this.onreadyCallbacks.forEach((cb) => cb());
	}

	bindInputs() {
		const block = this.block.querySelector('#loader');
		const input = block.querySelector('#loaderInput');
		const btn = block.querySelector('#loaderBtn');

		if (block) {
			function onDragOver(ev) {
				ev.preventDefault();
				block.classList.add('drag-over');
			}

			block.addEventListener('dragenter', onDragOver);
			block.addEventListener('dragover', onDragOver);

			function onDragLeave(ev) {
				ev.preventDefault();
				block.classList.remove('drag-over');
			}

			block.addEventListener('dragleave', onDragLeave);
			block.addEventListener('drop', onDragLeave);

			block.addEventListener('drop', (ev) => {
				try {
					this.handleFiles(ev.dataTransfer.files);
				} catch (error) {
					console.log('Сбой загрузки изображений');
				}
			});
		}

		if (input) {
			const _this = this;
			input.addEventListener('input', function (ev) {
				try {
					_this.handleFiles(this.files);
				} catch (error) {
					console.log('Сбой загрузки изображений');
				}
			});

			if (btn) {
				btn.addEventListener('click', (ev) => {
					input.click();
				});
			}
		}
	}

	async handleFiles(files) {
		let size = 0;
		files = [...files];
		const input = this.block.querySelector('#loaderInput');

		const reg = new RegExp(`\.(png|jpg|jpeg|gif|webp)('|")?$`);
		files = files.filter(file => file.name ? reg.test(file.name, 'i') : false);

		if (files.length === 0) return 'empty';

		// Добавляем спиннер
		const spinnerParent = document.querySelector('#loader');
		const divEl = document.createElement('div');
		divEl.className = 'spinner';
		divEl.innerHTML = `<divc class='spinner-border', role='status'><span class='visually-hidden'>Загрузка...</span>`;

		spinnerParent.appendChild(divEl);
		// Добавляем спиннер

		this.files.forEach((x) => size += x.size);
		files.forEach((x) => size += x.size);

		if (size > 50 * 1024 * 1024) {
			input.value = null;
			// Удаляем спиннер
			divEl.remove();
			return alert((files.length > 1 ? 'Загруженные файлы слишком велики.' : 'Загруженный файл слишком велик.') + ' Суммарный вес всех загруженных файлов не должен превышать 50 Мб.');
		}

		for (let i = 0; i < files.length; i++) {
			let newFile = await imageCompression(files[i], {
				maxWidthOrHeight: 1920,
				useWebWorker: true
			});
			const file = new UserFile(files[i].name, newFile, files[i].size);

			let k = this.files.findIndex((x) => x.name == file.name);

			if (k >= 0) {
				let conf = confirm(`Файл с именем ${file.name} уже загружен. Перезаписать?`);
				if (!conf) {
					// Удаляем спиннер
					divEl.remove();
					return;
				}

				let oldFile = this.files[k];

				await this.removeGalleryItem(oldFile);
			}

			this.files.push(file);
			await this.addGalleryItem(file);
			await this.setFile(file);
		}

		// Удаляем спиннер
		divEl.remove();
	}

	/**
	 * @param {UserFile} file
	 */
	addGalleryItem(file) {
		const src = URL.createObjectURL(file.data);

		const card = document.createElement('div');
		const img = document.createElement('img');

		const getCurrentComment = () => {
			const fileIndex = this.files.findIndex((x) => x.name == file.name);
			return this.files[fileIndex].comment || '';
		}

		const getButtonText = () => {
			return getCurrentComment() ? 'Редактировать комментарий' : 'Оставить комментарий';
		}


		card.classList.add('gallery-base-block', 'js-custom-block');
		card.setAttribute('data-fname', file.name);
		img.classList.add('gallery-base-image');
		img.src = src;

		card.appendChild(img);

		const remove = document.createElement('div');
		remove.classList.add('js-custom-remove');

		card.appendChild(remove);

		remove.addEventListener('click', (ev) => {
			const deleteConfirmText = window.brief.lang === "en" ? "Confirm deletion" : "Подтвердите удаление";

			const res = window.confirm(deleteConfirmText);

			if (res) {
				this.removeGalleryItem(file);
			}
		});

		return new Promise((res, rej) => {
			const onloaded = () => {
				img.removeEventListener('load', onloaded);
				setTimeout(() => {
					this.gallery.appendChild(card);
					this.masonry.appended(card);
					this.masonry.layout();
					return res();
				}, 200);
			};

			img.addEventListener('load', onloaded);
		});
	}

	/**
	 * @param {UserFile} file
	 */
	async removeGalleryItem(file) {
		const card = this.gallery.querySelector(`[data-fname="${file.name}"]`);

		if (card) {
			this.masonry.remove(card);
			this.masonry.layout();
		}

		await this.removeFileFromList(file);
	}

	async removeFileFromList(file) {
		let i = this.files.findIndex((x) => x.name == file.name);
		if (i >=0) {
			this.files.splice(i, 1);
			await this.removeFile(file);
		}
	}

	openIDB() {
		return new Promise((res, rej) => {
			let req = indexedDB.open('client-refs', CLIENT_REFS_V);

			req.onupgradeneeded = () => {
				return updateIDB(req.result);
			}

			req.onerror = () => {
				alert('Please refresh the page.');
				return rej(new Error('Error connecting to IDB.'))
			}

			req.onsuccess = () => {
				const db = req.result;
				db.onversionchange = () => {
					db.close();
					alert('Please refresh the page.');
				}

				let currentSession = cookies.get('u');
				if (currentSession) {
					let transaction = db.transaction(['meta', 'files'], 'readwrite');
					let meta = transaction.objectStore('meta');
					let files = transaction.objectStore('files');

					const storedSession = meta.get('session');

					storedSession.onsuccess = () => {
						if (storedSession.result) {
							if (currentSession != storedSession.result) {
								files.clear();
								meta.put(currentSession, 'session');
							}

							return;
						}

						meta.put(currentSession, 'session');
					}
				}

				this.db = db;
				return res(db);
			}
		});
	}

	readFilesFromIDB() {
		if (!this.db) return;

		let transaction = this.db.transaction('files', 'readonly');
		let files = transaction.objectStore('files');

		return new Promise((res, rej) => {
			let req = files.getAll();
			req.onsuccess = () => res(req.result);
			req.onerror = () => rej(req.error);
		});
	}

	/**
	 * @param {UserFile} file
	 */
	setFile(file) {
		let transaction = this.db.transaction('files', 'readwrite');
		let files = transaction.objectStore('files');

		return new Promise((res, rej) => {
			let req = files.put(file, file.name);
			req.onsuccess = () => res(req.result);
			req.onerror = () => rej(req.error);
		});
	}

	removeFile(file) {
		let transaction = this.db.transaction('files', 'readwrite');
		let files = transaction.objectStore('files');

		return new Promise((res, rej) => {
			let req = files.delete(file.name);
			req.onsuccess = () => res(req.result);
			req.onerror = () => rej(req.error);
		});
	}
}

function updateIDB(db) {
	switch (db.version) {
		case 0: updateIDB_0(db); break;
		default: updateIDB_0(db); break;
	}
}

function updateIDB_0(db) {
	db.createObjectStore('files');
	db.createObjectStore('meta');
}

module.exports = CustomReferenceController;
