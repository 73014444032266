const { BriefBase } = require('../../../core/models/brief');
const { structure, SLIDE_TYPE, sections } = require('../../../configs/brief.config');
const { bindEventsAndInitValues } = require('./bind-events-and-init-values');
const { fillGallery } = require('./fill-gallery');

/**
 * Класс, который отвечает за логику работы брифа и хранение данных брифа.
 */
class Brief extends BriefBase {	
	constructor () {
		super();

		/**
		 * Загружен ли файл брендбука.
		 * @type {boolean}
		 */
		this.brandbookFileUploaded = false;

		/**
		 * Текущая страница брифа.
		 * @type {number}
		 */
		this.activePage = 1;

		/**
		 * Страница, дальше которой клиент не проходил бриф.
		 * @type {number}
		 */
		this.latestPage = 1;

		/**
		 * Закончен ли бриф.
		 * @type {boolean}
		 */
		this.finished = false;

		/**
		 * Показывался ли прелоадер последней страницы.
		 * Добавлено, чтобы сократить время прелоадера при повторном открытии страницы.
		 * @type {boolean}
		 */
		this.preloaderShown = false;

		/**
		 * Показывался ли sidebar на странице.
		 * @type {boolean[]}
		 */
		this.shownSidebar = [];

		/**
		 * Загруженные референсы.
		 * @type {{fname: string, data: string, comment: string, type: string, lastModified: number}[]>}
		 */
		this.customReferences = [];

		/**
		 * Поле для комментария к загруженным референсам.
		 * @type {string|undefined}
		 */
		this.customRefsComment = undefined;

		/**
		 * Режим отладки.
		 */
		this.debugMode = false;

		for (let i = 0; i < structure.length; i++) this.shownSidebar.push(structure[i].showSidebar? false : true);

		this.lang = document.documentElement.getAttribute('lang');

		this.demoMode = false;
	}

	init () {
		const $brief = document.querySelector('.brief');
		const isManagementBoard = $brief.classList.contains('body--management');
		const $gallery = Array.from(document.querySelectorAll('.js-gallery-base-container'));
		const currentSlide = document.body.getAttribute('data-slide');

		const uToken = window.cookies.get('u');
		if (uToken === 'demo') this.demoMode = true;

		if ($brief) {
			this.restore();

			if (currentSlide && this.activePage != currentSlide) {
				this.activePage = currentSlide;
				cookies.set('p', currentSlide);
			}

			bindEventsAndInitValues();

			if (!isManagementBoard) {
				this.showSidebar();
				this.checkValidContinue();
				this.checkValidReturn();
			}
		}

		if ($gallery.length) {
			window.scroll(0, 0);
			$gallery.map(x => fillGallery(x));
		}
	}

	showSidebar () {
		if (!this.shownSidebar[this.activePage - 1]) {
			const sidebar = document.querySelector('.sidebar');
			sidebar.classList.add('is-open');

			setTimeout(() => {
				sidebar.classList.remove('is-open');
				brief.shownSidebar[this.activePage - 1] = true;
				brief.save();
			}, structure[this.activePage - 1].sidebarTime? structure[this.activePage - 1].sidebarTime : 3000);
		}
	}

	/**
	 * Сохраняет бриф в `localStorage`.
	 */
	save () {
		let currentToken = cookies.get('u');
		localStorage['brief'] = JSON.stringify(this);
		localStorage['token'] = currentToken;
	}

	/**
	 * Проверяет `localStorage` на наличие брифа.
	 * Если он там, есть то загружает состояние оттуда.
	 * Объявляет глобальнуб переменную `brief`.
	 */
	restore () {

		/**
		 * Сохраненная версия брифа.
		 * @type {?Brief}
		 */
		let savedBrief = localStorage['brief'] ? JSON.parse(localStorage['brief']) : null;
		let currentToken = cookies.get('u'),
			savedToken = localStorage['token'] || null,
			isManagement = document.body.classList.contains('body--management')

		if (savedBrief && (currentToken == savedToken || isManagement)) {
			for (let key in savedBrief) {
				this[key] = savedBrief[key];
			}
		} else {
			cookies.set('p', 1);
		}

		window.brief = this;
	}


	/**
	 * Осуществляет проверку на наличие хотя бы одного выбранного фото на текущем слайде.
	 * true == пусто
	 */
	checkNoImageSelected () {
		const pageConfig = structure[brief.activePage - 1];

		if (/^gallery/i.test(pageConfig.type)) {
			const pageSection = pageConfig.section;
			const section = sections[pageSection];
			const subsections = section['subsections'];
			let result = true;

			if (subsections.length) {
				subsections.forEach((subsection) => {
					if (this.selectedImages[subsection['code']].length != 0) result = false;
				});

				return result;
			}

			if (this.selectedImages[section['code']].length == 0) {
				return result;
			}
		}

		return false;
	}

	/**
	 * Осуществляет проверку на выполнение всех условий жтого и предыдущих слайдов для перехода далее.
	 */
	checkValidContinue () {
		const $continue = document.querySelector('#continue');
		const $send = document.querySelector('#send');
		if (!$continue && !$send) return false;

		let valid = true;
		let preloader = components['Preloader']? !components['Preloader'].enabled : true;

		for (let i = 0; i < brief.activePage; i++) {
			if (!structure[i].test(brief)) {
				valid = false;
				break;
			}
		}

		if (valid && preloader) {
			$continue? $continue.classList.remove('btn--disabled'):
				$send.classList.remove('btn--disabled');
		} else {
			$continue? $continue.classList.add('btn--disabled'):
				$send.classList.add('btn--disabled');
		}

		return valid;
	}

	/**
	 * Осуществляет проверку возможности вернуться назад.
	 */
	checkValidReturn () {
		const $return = document.querySelector('#return');
		if (!$return) return false;

		let valid = true;
		let preloader = components['Preloader']? !components['Preloader'].enabled : true;

		if (brief.activePage == structure.length) valid = false;
		if (brief.activePage == 1) valid = false;
		if (!(brief.activePage > 1 && structure[brief.activePage - 1].group == structure[brief.activePage - 2].group)) valid = false;

		console.log(valid, preloader)

		if (valid && preloader) {
			$return.classList.remove('btn--disabled');
		} else {
			$return.classList.add('btn--disabled');
		}

		return valid;
	}

	/**
	 * Осуществляет переход на следующий слайд.
	 * @param {() => void} cb
	 */
	gotoNext (cb) {
		if (brief.finished) {
			cookies.set('p', structure.length);
			return location.reload();
		}

		if (brief.activePage != structure.length) {
			do {
				brief.activePage++; 
			} while (this.demoMode && structure[brief.activePage - 1].skipInDemo);
		}

		if (brief.activePage == structure.length) {
			brief.finished = true;
		}

		brief.latestPage = Math.max(brief.latestPage, brief.activePage);
		brief.save();

		console.log(cb);

		brief.sync((res) => {
			cookies.set('p', brief.activePage);
			location.reload();
		}, (err) => {
			console.error(err);
			if (typeof cb == 'function') cb();
		});
	}

	/**
	 * Осуществляет переход на предыдущий слайд.
	 */
	gotoPrevious () {
		if (brief.finished) {
			cookies.set('p', structure.length);
			return location.reload();
		}

		if (brief.activePage != 1) {
			do {
				brief.activePage--;
				console.log(brief.activePage, structure[brief.activePage - 1]);
			} while (this.demoMode && structure[brief.activePage - 1].skipInDemo);
		}

		brief.save();

		brief.sync((res) => {
			cookies.set('p', brief.activePage);
			location.reload();
		}, (err) => {
			console.error(err);
		});
	}

	/**
	 * Отправляет текущее состояние брифа на сервер.
	 */
	sync (onSuccess, onError) {
		if (this.demoMode) {
			return onSuccess(JSON.stringify({isDemo: true}));
		}

		let req = new XMLHttpRequest();
		req.open('POST', `${BASE_URI}/sync`, true);
		req.setRequestHeader('Content-Type', 'text/plain');
		req.send(JSON.stringify(brief));

		req.onreadystatechange = function(ev) {
			if (req.readyState === XMLHttpRequest.DONE) {
				if (req.status == 200) {
					onSuccess(req.responseText);
				} else {
					onError(req.responseText)
				}
			}
		}
	}

	/**
	 * Отправляет запрос о завершении брифа на сервер.
	 */
	async close (onSuccess, onError) {
		if (this.demoMode) {
			return onSuccess(JSON.stringify({isDemo: true}));
		}

		let req = new XMLHttpRequest();
		let fd = new FormData();

		/** Очистка
		 * Если в группе изображений ([3d-graphics] == [key])
		 * остался Эл. без комментария, то удаляем его из группы перед отправкой
		 for (const key in this.selectedImages) {
			 if (this.selectedImages[key].length) {
				this.selectedImages[key] = this.selectedImages[key].filter((img) => {
					if (!this.imageComments[key][img] || this.imageComments[key][img].length === 0) {
						return false;
					} else {
						return true;
					}
				});
			}
		}
		*/

		await new Promise((res, rej) => {
			components.CustomReferenceController.setOnReadyCallback(() => {
				res();
			});
		});

		const toBase64 = (blob) => {
			return new Promise((res, rej) => {
				let fr = new FileReader();
				fr.readAsDataURL(blob);
				fr.onloadend = function () {
					res(fr.result);
				};
			});
		}

		// let refsWithComments = components.CustomReferenceController.files.filter((x) => x.comment && x.comment != '');

		this.customReferences = await Promise.all(components.CustomReferenceController.files.map(async (x) => {
			return {
				fname: x.name,
				data: await toBase64(x.data),
				type: x.data.type,
				lastModified: x.data.lastModified || new Date(),
				comment: x.comment
			}
		}));

		fd.append('briefData', JSON.stringify(this));

		req.onreadystatechange = function() {
			if (req.readyState == XMLHttpRequest.DONE) {
				if (req.status == 200) {
					onSuccess(req.response);
				} else {
					onError(req.response);
				}
			}
		}

		req.open('POST', `${BASE_URI}/close`, true);
		req.send(fd);
	}

	/**
	 * Отображает сообщение о завершении брифа.
	 * Блокирует прокрутку окна.
	 */
	showEndModal() {
		const $modal = document.querySelector('.js-modal--finished');

		if ($modal) {
			$modal.classList.add('is-active');
			document.querySelector('html').style.overflow = 'hidden';
		}

		const $emptyComment = document.querySelector('.js-modal--comment');
		if ($emptyComment && document.querySelectorAll('.js-block-comment--off').length) {
			$emptyComment.classList.add('is-active', 'is-sending');
		}

	}

	/**
	 * Меняет содержимое сообщения о завершении брифа.
	 */
	updateEndModal(res) {
		const $modal = document.querySelector('.js-modal--finished');
		const $content = document.querySelector('.modal--finished__content');
		// const $text = document.querySelector('.modal--finished__text');
		const $preloader = document.querySelector('.modal--finished__preloader');

		if ($modal) {
			$preloader.style.display = 'none';
			$content.style.display = 'flex';

			// $text.innerHTML = `Бриф завершен.<br>Наш менеджер в скором времени свяжется с вами.`;
			$modal.setAttribute('data-url', res.pdf);
		}
	}

	/** Utils
	 * очистка текстовых полей
	 */
	_sanitize(str = '') {
		return str
			.trim()
			.replace(/\s+/g, ' ')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#039;')
			.replace(/\|/g, '') // | - временный спецсимвол разделитель
			.replace(/\^/g, '') // | - временный спецсимвол разделитель
	}
}

module.exports = Brief;
