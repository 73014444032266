const { structure, sections, SLIDE_TYPE } = require('../../../configs/brief.config');
const { Color } = require('../../../core/models/color');


/**
 * Заполняет галлерею изображениями.
 * @param {HTMLElement} gallery Блок галлереи.
 */
function fillGallery (gallery) {
	const sectionCode = gallery.getAttribute('data-filter');
	let sectionConfig = sections.find(x => x.code == sectionCode);
	const slideConfig = structure[brief.activePage - 1];

	if (!sectionConfig) {
		for (let i = 0; i < sections.length; i++) {
			for (let j = 0; j < sections[i].subsections.length; j++) {
				if (sections[i].subsections[j].code == sectionCode) {
					sectionConfig = sections[i].subsections[j];
					break;
				}
			}
		}
	}

	if (!sectionConfig) {
		sectionConfig = {};
	}

	const loadMore = document.createElement('button');
	loadMore.classList.add('btn', 'btn--load-more');
	gallery.appendChild(loadMore);
	loadMore.skipAmount = 10;
	loadMore.innerText = brief.lang === 'ru' ? 'Загрузить еще' : 'Load more';

	if (slideConfig.type === SLIDE_TYPE.GALLERY_CB) {
		const updateFunction = updateGalleryCB(gallery, sectionCode, sectionConfig);
		const $checkboxes = Array.from(document.querySelectorAll('.gallery-sort__input'));

		$checkboxes.map(x => x.addEventListener('change', function (ev) {
			loadMore.skipAmount = 10;
			updateFunction(0);
		}));

		loadMore.addEventListener('click', function (ev) {
			updateFunction(this.skipAmount);
			this.skipAmount += 10;
		});

		updateFunction(0);
	} else {
		const updateFunction = updateGallery(gallery, sectionCode, sectionConfig);

		loadMore.addEventListener('click', function (ev) {
			updateFunction(this.skipAmount);
			this.skipAmount += 10;
		});

		updateFunction(0);
	}
}

function updateGalleryCB(gallery, sectionCode, sectionConfig) {
	let images = {};

	return async function (skipAmount) {
		const $checkboxes = Array.from(document.querySelectorAll('.gallery-sort__input'));
		let sections = [];
		let code = '';

		$checkboxes.map(async x => {
			if (x.checked) {
				sections.push(x.getAttribute('data-filter'));
			}

			let $more = [...gallery.childNodes].find(x => {
				if (x.nodeType != 1) { // Исключение попадания остального муора, который попадает в childNodes
					return false;
				}
				return x.classList.contains('btn--load-more');
			});
			if ($more) $more.style.display = 'block';

			code += Number(x.checked);
		});

		let opts = {
			sections: sections.length? sections : [sectionCode],
			random: true,
			startFrom: skipAmount,
			segments: brief.segments.length? brief.segments : [],
			amount: 10
		};

		if (sectionConfig.ignoreColor) opts.ignoreColor = true;
		if (sectionConfig.ignoreSector) opts.ignoreSegment = true;
		if (brief.colors.main) opts.colors = [brief.colors.main];
		if (brief.colors.addtional) opts.colors.push(brief.colors.addtional);
		if (brief.segments.length == 0) opts.ignoreSegment = true;

		if (!images[code]) images[code] = [];

		let res = await imageRequest(opts);
		if (res.urls) images[code] = images[code].concat(res.urls);

		if (res.last) {
			let $more = [...gallery.childNodes].find(x => {
				if (x.nodeType != 1) { // Исключение попадания остального муора, который попадает в childNodes
					return false;
				}

				return x.classList.contains('btn--load-more');
			});
			if ($more) $more.style.display = 'none';
		}

		displayImages(gallery, sectionCode, images[code]);
		return images[code];
	}
}

function updateGallery(gallery, sectionCode, sectionConfig) {
	let images = [];
	let tags = [];

	return async function (skipAmount) {
		let opts = {
			sections: [sectionCode],
			random: true,
			startFrom: skipAmount,
			segments: brief.segments.length? brief.segments : [],
			amount: 10
		};

		if (sectionConfig.ignoreColor) opts.ignoreColor = true;
		if (sectionConfig.ignoreSector) opts.ignoreSegment = true;
		if (brief.colors.main) opts.colors = [brief.colors.main];
		if (brief.colors.addtional) opts.colors.push(brief.colors.addtional);
		if (brief.segments.length == 0) opts.ignoreSegment = true;

		let res = await imageRequest(opts);
		if (res.urls) images = images.concat(res.urls);
		if (res.tags) tags = tags.concat(res.tags);

		if (res.last) {
			let $more = [...gallery.childNodes].find(x => {
				if (x.nodeType != 1) { // Исключение попадания остального муора, который попадает в childNodes
					return false;
				}

				return x.classList.contains('btn--load-more');
			});

			if ($more) $more.remove();
		}

		displayImages(gallery, sectionCode, images, tags);
		return images;
	}
}

/**
 * Добавляет изображения в галлерею.
 * @param {HTMLElement} gallery Блок галлереи.
 */
function displayImages(gallery, section, images, tags) {
	if (images && images.length > 0) {
		components['Gallery'].clear_gallery(gallery, section);
		components['Gallery'].init_gallery(images, gallery, section, tags);
	} else {
		gallery.innerText = window.brief.lang === "en" ? "Nothing was found :(" : "Ничего не нашлось :(";
		components['Preloader'].disablePreloader();
	}
}

/**
 * Отправляет запрос к базе картинок.
 * @param {{section: string, ignoreColor?: boolean, segments?: string[], colors?: Color[], ignoreSegment?: boolean, random?: boolean, startFrom?: number}} opts Параметры запроса.
 */
function imageRequest(opts) {
	return new Promise((resolve, reject) => {
		$.ajax({
			type: 'POST',
			url: `${BASE_URI}/api/images/query/brief`,
			data: opts,
			success: x => resolve(x),
			error: x => reject(x)
		});
	});
}

function minifyArray(arr) {
	let newArr = [];

	arr.forEach((e) => {
		if (!newArr.includes(e)) newArr.push(e);
	});

	return newArr;
}

module.exports = {
	fillGallery: fillGallery
}
