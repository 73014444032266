class Color {
	constructor (r, g, b) {

		/**
		 * Содержание красного от 0 до 255.
		 */
		this.r = r? r : 0;

		/**
		 * Содержание зеленого от 0 до 255.
		 */
		this.g = g? g : 0;

		/**
		 * Содержание синего от 0 до 255.
		 */
		this.b = b? b : 0;
	}

	/**
	 * Создать цвет из hex-строки.
	 * @param {string} hex Цвет hex-строкой.
	 */
	static fromHex (hex) {
		let r = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i.exec(hex);

		return r? new Color(parseInt(r[1], 16), parseInt(r[2], 16), parseInt(r[3], 16)) : null;
	}

	/**
	 * Вывести цвет как hex-строку.
	 */
	asHex () {
		function h(c) {
			let hex = c.toString(16);
			return hex.length == 1 ? '0' + hex : hex;
		}

		return `#${h(this.r)}${h(this.g)}${h(this.b)}`;
	}
}

module.exports = {
	Color: Color
}
