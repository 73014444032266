const { sections } = require('../../../configs/brief.config');
const Masonry = require('masonry-layout/masonry.js');

const SpecialPhotosConfig = [
	{
		ru: "Эффект-наложения",
		en: "Overlay Effect",
	},
	{
		ru: "Псевдореализм",
		en: "Pseudorealism",
	},
	{
		ru: "Нестандартный-ракурс",
		en: "Unconventional Angle",
	},
	{
		ru: "Мультиэкспозиция",
		en: "Multiple Exposure",
	},
	{
		ru: "Коллаж+геометрия",
		en: "Collage+Geometry",
	},
	{
		ru: "За-работой",
		en: "At Work",
	},
	{
		ru: "Дополненная-реальность",
		en: "Augmented Reality",
	},
	{
		ru: "Бизнес-стиль",
		en: "Business Style",
	},
	{
		ru: "Cinema",
		en: "Cinema",
	},
	{
		ru: "Эффект-наложения",
		en: "Overlay Effect",
	},
	{
		ru: "Макро",
		en: "Macro",
	},
	{
		ru: "Активы",
		en: "Assets",
	},
];

class Gallery {
	constructor() {

		this.collection = {};
		this.loadState = [];

		this._collection = {};
		// this.winH = 1240; // 1240 - 20 top indent
		this.winH = 1140; // 1240 - 100
		/** Проблема размеров
			берем максимум высоты в 1100рх
			имея запас от максимальной в 1140рх
			рассчитаем самый длинный коммент
			и самый большой набор чекбоксов

			рассчитаем и зададим ограничение для:
			b-gallery-final-block__image
			с учетом блоков (113px)
			и текста (140рх)

			TODО:
			Попробовать сделать на лету,
			до инита и реинита массонри.
			Тогда можно учесть наличие или
			отсутсвие комментов и\или чебоксов

			1140 - 113 - 140 = 887
		 */
		this.imgHeightBreake = 870; // old 1000
	}

	init() {

		document.addEventListener("DOMContentLoaded", onEvent);
		document.addEventListener("GalleryForceInit", onEvent);

		const gallery = this;

		async function onEvent(ev) {

			// gallery.tabsWrapper = document.querySelector('.js-tabs');
			// if (gallery.tabsWrapper) {
			// }
			gallery.init_tabs();

			const imagesCollection = brief.selectedImages;
			await new Promise((res, rej) => {
				components.CustomReferenceController.setOnReadyCallback(() => {
					res();
				});
			});
			// условие рендера финальной страницы
			if (document.querySelector('.js-final') || ev.type == 'GalleryForceInit') {
				cookies.rm('anchor');

				// обошли выбранное
				for (const key in imagesCollection) {
					// обошли не пустые группы
					if (imagesCollection[key].length) {
						imagesCollection[key].forEach((el, index, arr) => {
							// el - iid
							// key - group
							// brief.imageComments[key][el] - коммент txt -- off
							gallery.createFinalTemplate(el, key);
						});
					}
				}

				gallery.createCustomTemplate();

				// [TODO]
				const empty = document.querySelectorAll('[data-header-group]:not(.not-empty)');
				empty.forEach((el) => {
					let span = document.createElement('span');
					span.innerText = window.brief.lang === "en" ? "No\u00A0images selected in\u00A0this category" : "Нет выбранных изображений в\u00A0этой категории";
					el.firstChild.appendChild(span);
				});

				/** [TODO]
				briefConfig.sections.forEach((section) => {
					if (section.hideOnFinal) {
						document.querySelectorAll(`[data-header-group="${section.code}"]`).forEach(x => x.remove());
						document.querySelectorAll(`[data-target-group="${section.code}"]`).forEach(x => x.remove());
					}

					if (section.subsections) {
						section.subsections.forEach((subsection) => {
							if (subsection.hideOnFinal) {
								document.querySelectorAll(`[data-header-group= "${subsection.code}"]`).forEach(x => x.remove());
								document.querySelectorAll(`[data-target-group="${subsection.code}"]`).forEach(x => x.remove());
							}
						});
					}
				}); */

				const finalCardsCollection = [...document.querySelectorAll('.js-gallery-final-block')];
				finalCardsCollection.forEach(card => {
					gallery.setCardsParams(card);
				});
				/** if (document.querySelectorAll('.js-block-comment--off').length) {
					if (window.components['Modals'].emptyComment) {
						window.components['Modals'].emptyComment.classList.add('is-active');
					}
				} */

				// new
				const disablePreloader = () => {
					if (!document.body.classList.contains('body--management')) window.scroll(0, 0);
					window.components['Preloader'].disablePreloader();
					brief.preloaderShown = true;
					brief.save();
				}

				if (!brief.demoMode) { setTimeout(() => disablePreloader(), brief.preloaderShown? 1000 : 5000); }
				else disablePreloader();
			}

			// условие рендера страницы принта
			if (document.querySelector('.js-print')) {
				let printPageCrutch = 0;
				let printPageCrutchCounter = 0;

				if (components.CustomReferenceController.files.length > 0) {
					imagesCollection.custom = components.CustomReferenceController.files.map(obj => URL.createObjectURL(obj.data));
				}

				const prepareToPrint = async (key, arr) => {

					for (let val of arr) { /** async iterator */
						await gallery._createFinalTemplate(val, key, arr);
						printPageCrutchCounter++;
						console.log(`base: ${printPageCrutch}`, `calc: ${printPageCrutchCounter}`);

						if (printPageCrutch === printPageCrutchCounter) {
							document.querySelector('body').classList.add('allImagesIsReady');
						}
					}
				};
				for (const key in imagesCollection) {
					printPageCrutch += imagesCollection[key].length;
				}
				// обошли выбранное
				for (const key in imagesCollection) {  /** parallels! */
					// обошли не пустые группы
					if (imagesCollection[key].length) {
						/**
						 *
						 */
						const header = document.querySelector(`[data-header-group="${key}"]`);
						if (header) {
							header.classList.add('js-header-use');
						}
						 /** */

						prepareToPrint(key, imagesCollection[key]);  /** async iterator */

					}
				}
			}

		};

	}

	createCustomTemplate() {
		const parentBlock = document.querySelector(`[data-target-group="custom"] .row`);
		if (!parentBlock) return false;

		if (components.CustomReferenceController.files.length > 0) {
			document.querySelector(`[data-header-group="custom"]`).classList.add('not-empty');
			document.querySelector(`[data-target-group="custom"]`).classList.add('not-empty');

			components.CustomReferenceController.files.forEach((file, j) => {
				let url = URL.createObjectURL(file.data);

				const html = `<div class="col-xs-12 col-sm-6 col-md-4 js-container col-print-4"><div class="b-gallery-final-block js-gallery-final-block" iid="!custom${j}" data-target-group="custom"><div class="b-gallery-final-block__image js-gallery-final-img--hover"><img class="b-gallery-final-block__img" alt="img" src="${url}"><div class="b-gallery-final-block__img-del js-gallery-final-img--del" title="Удалить"></div></div></div></div>`;

				parentBlock.innerHTML += html;
			});
		}
	}

	// url - iid - ссылка на фото и уникальный код
	// group - группа к которой принадлежит выборка - определяет по ID в какой блок пойдут эл.
	// txt - текст комментария или условие его отстутсвия
	createFinalTemplate(url, group, txt = false) {
		if (group == 'custom') return false;
		const parentBlock = document.querySelector(`[data-target-group="${group}"] .row`);
		if (!parentBlock) return false;

		document.querySelector(`[data-header-group="${group}"]`).classList.add('not-empty');
		document.querySelector(`[data-target-group="${group}"]`).classList.add('not-empty');

		// b-gallery-final-block

		// js-gallery-final-area
		// js-gallery-final-img--hover
		// js-gallery-final-img--del
		// js-gallery-final-comment--del
		// js-gallery-final-comment--add

		// js-container
		let section = [];
		sections.forEach(sec => { /** Считаем "code" абсолютно уникальным */

			if (sec.code === group && sec.checkboxes) { /** пока не задан дефолный параметр checkboxes: [] */
				section = sec.checkboxes;
			} else if (sec.subsections.length) {
				sec.subsections.forEach(sub => {
					if (sub.code === group && sub.checkboxes) section = sub.checkboxes;
				});
			}

		});

		let html = `<div class="col-xs-12 col-sm-6 col-md-4 js-container col-print-4"><div class="b-gallery-final-block js-gallery-final-block" iid="${url}" data-target-group="${group}"><div class="b-gallery-final-block__image js-gallery-final-img--hover"><img class="b-gallery-final-block__img" alt="img" src="${BASE_URI}/api/images/get/${url}"><div class="b-gallery-final-block__img-del js-gallery-final-img--del" title="Удалить"></div></div><div class="b-gallery-final-block__comment-btn js-gallery-final-comment--add"></div><div class="b-gallery-final-block__comment-area">

		<ul class="form-checkbox__list js-final-block-checkboxes">
			<li class="form-checkbox__item is-empty" data-id-cb="${section[0] ? section[0].id : ''}">
				<div class="form-checkbox__label">
					<span class="form-checkbox__txt">${section[0] ? section[0].name[brief.lang] : ''}</span>
					<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
					<span class="form-checkbox__checkbox"></span>
				</div>
			</li>
			<li class="form-checkbox__item is-empty" data-id-cb="${section[1] ? section[1].id : ''}">
				<div class="form-checkbox__label">
					<span class="form-checkbox__txt">${section[1] ? section[1].name[brief.lang] : ''}</span>
					<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
					<span class="form-checkbox__checkbox"></span>
				</div>
			</li>
			<li class="form-checkbox__item is-empty" data-id-cb="${section[2] ? section[2].id : ''}">
				<div class="form-checkbox__label">
					<span class="form-checkbox__txt">${section[2] ? section[2].name[brief.lang] : ''}</span>
					<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
					<span class="form-checkbox__checkbox"></span>
				</div>
			</li>
			<li class="form-checkbox__item is-empty" data-id-cb="${section[3] ? section[3].id : ''}">
				<div class="form-checkbox__label">
					<span class="form-checkbox__txt">${section[3] ? section[3].name[brief.lang] : ''}</span>
					<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
					<span class="form-checkbox__checkbox"></span>
				</div>
			</li>
			<li class="form-checkbox__item is-empty" data-id-cb="${section[4] ? section[4].id : ''}">
				<div class="form-checkbox__label">
					<span class="form-checkbox__txt">${section[4] ? section[4].name[brief.lang] : ''}</span>
					<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
					<span class="form-checkbox__checkbox"></span>
				</div>
			</li>
			<li class="form-checkbox__item is-empty" data-id-cb="${section[5] ? section[5].id : ''}">
				<div class="form-checkbox__label">
					<span class="form-checkbox__txt">${section[5] ? section[5].name[brief.lang] : ''}</span>
					<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
					<span class="form-checkbox__checkbox"></span>
				</div>
			</li>

		</ul>
		<div class="b-final-block-text js-final-block-text"></div>

		</div></div></div>`;

		// if (group == 'with-people' || group == 'without-people') ==> 'photo' || 'pic'
		if (!section.length) {
			let crutchTtile = brief.imageComments[group] && brief.imageComments[group][url] ? `<h2>${brief.imageComments[group][url].replace('-', ' ')}</h2>` : '';

			html = `<div class="col-xs-12 js-container"><div class="b-gallery-final-block js-gallery-final-block" iid="${url}" data-target-group="${group}"><div class="b-gallery-final-block__image js-gallery-final-img--hover">${crutchTtile}<img class="b-gallery-final-block__img" alt="img" src="${BASE_URI}/api/images/get/${url}"><div class="b-gallery-final-block__img-del js-gallery-final-img--del" title="Удалить"></div></div></div></div>`;
		}

		parentBlock.innerHTML += html;
	}

	async _createFinalTemplate(url, group, arr) {

		let section = []; // Костыльно определяем группу буез чекбоксов и настраиваем верстку под это условие

		// метод создания обертки для каждой группы "в 1 экран"
		const createBox = (parent, name) => {
			// todo: дописать надежное обращение по ID
			let x = `<div class="gallery-final__box js-page-box">${!section.length ? '<div class="grid-sizer js-grid-item--50"></div>' : '' }</div>`;
			parent.insertAdjacentHTML('beforeend', x);
		}

		sections.forEach(sec => { /** Считаем "code" абсолютно уникальным */

			if (sec.code === group && sec.checkboxes) { /** пока не задан дефолный параметр checkboxes: [] */
				section = sec.checkboxes;
			} else if (sec.subsections.length) {
				sec.subsections.forEach(sub => {
					if (sub.code === group && sub.checkboxes) section = sub.checkboxes;
				});
			}

		});

		// шаблон карточки без интерактивных полей
		let card = '';
		let masonryConfig = {
			itemSelector: '.js-grid-item',
			gutter: 10,
			columnWidth: 500,
			initLayout: false
		}

		if (group == 'custom') {
			// src="${BASE_URI}/files/${url}" || src="${url}"
			card = `<div class="js-grid-item b-gallery-final-block" iid="${url}" data-target-group="${group}">
			<div class="b-gallery-final-block__image">
				<img class="b-gallery-final-block__img" alt="${group} _ ${url}" src="${BASE_URI}/files/${url}">
			</div></div>`;

			if (arr.indexOf(url) >= arr.length - 1 && brief.customRefsComment) {
				card += `<div class="b-gallery-final-block__comment-area js-grid-item">
					<p class="b-gallery-final-block__text">${brief.customRefsComment}</p>
				</div>`;
			}

		} else if (!section.length) {
			masonryConfig.itemSelector = '.js-grid-50';
			masonryConfig.columnWidth = '.grid-sizer';
			masonryConfig.percentPosition = true;

			let crutchTtile = brief.imageComments[group] && brief.imageComments[group][url] ? `<h2>${brief.imageComments[group][url].replace('-', ' ')}</h2>` : '';

			card = `<div class="js-grid-50 b-gallery-final-block" iid="${url}" data-target-group="${group}">
			<div class="b-gallery-final-block__image">
				${crutchTtile}
				<img class="b-gallery-final-block__img" alt="img" src="${BASE_URI}/api/images/get/${url}">
			</div>`

		} else {

			const data = window.components['Modals']._getCommentText(group, url);

			card = `<div class="js-grid-item b-gallery-final-block" iid="${url}" data-target-group="${group}">
				<div class="b-gallery-final-block__image">
					<img class="b-gallery-final-block__img" alt="img" src="${BASE_URI}/api/images/get/${url}">
				</div>

				<ul class="form-checkbox__list js-final-block-checkboxes">
					<li class="form-checkbox__item" data-id-cb="${section[0] ? section[0].id : ''}" style="display: ${data.fd.includes(section[0] ? section[0].id : '__empty__') ? 'block' : 'none'};">
						<div class="form-checkbox__label">
							<span class="form-checkbox__txt">${section[0] ? section[0].name[brief.lang] : ''}</span>
							<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
							<span class="form-checkbox__checkbox"></span>
						</div>
					</li>
					<li class="form-checkbox__item" data-id-cb="${section[1] ? section[1].id : ''}" style="display: ${data.fd.includes(section[1] ? section[1].id : '__empty__') ? 'block' : 'none'};">
						<div class="form-checkbox__label">
							<span class="form-checkbox__txt">${section[1] ? section[1].name[brief.lang] : ''}</span>
							<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
							<span class="form-checkbox__checkbox"></span>
						</div>
					</li>
					<li class="form-checkbox__item" data-id-cb="${section[2] ? section[2].id : ''}" style="display: ${data.fd.includes(section[2] ? section[2].id : '__empty__') ? 'block' : 'none'};">
						<div class="form-checkbox__label">
							<span class="form-checkbox__txt">${section[2] ? section[2].name[brief.lang] : ''}</span>
							<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
							<span class="form-checkbox__checkbox"></span>
						</div>
					</li>
					<li class="form-checkbox__item" data-id-cb="${section[3] ? section[3].id : ''}" style="display: ${data.fd.includes(section[3] ? section[3].id : '__empty__') ? 'block' : 'none'};">
						<div class="form-checkbox__label">
							<span class="form-checkbox__txt">${section[3] ? section[3].name[brief.lang] : ''}</span>
							<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
							<span class="form-checkbox__checkbox"></span>
						</div>
					</li>
					<li class="form-checkbox__item" data-id-cb="${section[4] ? section[4].id : ''}" style="display: ${data.fd.includes(section[4] ? section[4].id : '__empty__') ? 'block' : 'none'};">
						<div class="form-checkbox__label">
							<span class="form-checkbox__txt">${section[4] ? section[4].name[brief.lang] : ''}</span>
							<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
							<span class="form-checkbox__checkbox"></span>
						</div>
					</li>
					<li class="form-checkbox__item" data-id-cb="${section[5] ? section[5].id : ''}" style="display: ${data.fd.includes(section[5] ? section[5].id : '__empty__') ? 'block' : 'none'};">
						<div class="form-checkbox__label">
							<span class="form-checkbox__txt">${section[5] ? section[5].name[brief.lang] : ''}</span>
							<input class="form-checkbox__input" type="checkbox" checked="true" name="img-checkbox">
							<span class="form-checkbox__checkbox"></span>
						</div>
					</li>

				</ul>

				<div class="b-gallery-final-block__comment-area">
					<p class="b-gallery-final-block__text">${data.txt}</p>
				</div></div>`;

		}

		// обертка для всей целевой группы
		const parentBox = document.querySelector(`[data-target-group="${group}"]`);

		// проверить, вставлялись ли обертки ранее
		let a = parentBox.querySelector('.gallery-final__box');
		// создать, если нет
		if (!a) createBox(parentBox, group);

		// todo: дописать надежное обращение по ID
		// получить последнюю не пустую
		let box = parentBox.querySelectorAll(`.gallery-final__box:not(.full)`)[0];
		// заполнить
		box.innerHTML += card;

		// последняя вставленная картинка
		let img = box.querySelector('img');


		// создать экземпляр Масонри
		const instance = new Masonry(box, masonryConfig);
		// instance.once( 'layoutComplete', () => {
		// 	setTimeout(() => {
		// 		console.log('onLayout');
		// 		resolve();
		// 	}, 50);
		// } );

		return new Promise(resolve => {
			// ждем загрузку последней картинки
			img.onload = () => {
				if (img.scrollHeight > this.imgHeightBreake) {
					img.style.height = '870px'; // В данный момент триггер "имено этого" layout() наступает раньше проверки [old 1000px]
				}

				// инитим Масонри для перестроения
				// this._collection[group].layout();
				instance.layout();

				setTimeout(() => {
					resolve(instance);
				}, 50);
			}
			img.onerror = () => {
				console.error(img);
				instance.layout();

				setTimeout(() => {
					resolve(instance);
				}, 50);
			}

		}).then((currentInstance) => {
			return new Promise((resolve, reject) => {
				// сравнить размеры группы с шаблоном
				// todo: учесть стили, скролл, ограничения

				if (box.scrollHeight > this.winH) {
					[...box.querySelectorAll('.js-grid-item img')].forEach(image => {
						if (image.scrollHeight > this.imgHeightBreake) {
							image.style.height = '870px';
						}
					});
				}

				if (box.scrollHeight > this.winH) {
					// let items = box.querySelectorAll('.js-grid-item'); // TODO: !section не удаляется?
					let items = [...box.querySelectorAll('.js-grid-item'), ...box.querySelectorAll('.js-grid-50')]; // типа блоки не могут иметь оба класса

					items[items.length - 1].remove();
					// this._collection[group].layout();
					currentInstance.layout();
					box.classList.add('full');

					createBox(parentBox, group);
					let r = parentBox.querySelectorAll(`.gallery-final__box:not(.full)`)[0];
					r.innerHTML += card;
				}

				setTimeout(() => {
					resolve();
				}, 50);

			});
		});
	}

	init_tabs() {
		const tabsBtnsCollection = [...document.querySelectorAll('.js-tabs-btn')];
		const tabsSectionsCollection = [...document.querySelectorAll('.js-tabs-section')];

		if (tabsBtnsCollection.length && tabsSectionsCollection.length) {
			tabsBtnsCollection.forEach(btn => {

				btn.addEventListener('click', event => {
					const targetAttr = event.currentTarget.getAttribute('data-section-target');
					tabsClean();

					event.currentTarget.classList.add('is-active');
					tabsSectionsCollection.forEach(tab => {
						if (tab.getAttribute('data-section-id') == targetAttr) {
							tab.classList.add('is-active');
						}
					});

					const masonryId = event.currentTarget.getAttribute('data-gallery-id');
					if (this.collection[masonryId]) {
						this.collection[masonryId].layout();
					}
				});

			});

			if (!brief.finished) return;
			let anchor = cookies.get('anchor');
			if (!anchor) return;

			/** {slid: '5', target: '2'} */
			anchor = JSON.parse(anchor);

			if (anchor.slide != brief.activePage) return;

			const btn = document.querySelector(`.js-tabs-btn[data-section-target="#__${anchor.target}"]`);
			const section = document.querySelector(`.js-tabs-section[data-section-id="#__${anchor.target}"]`);
			if (!btn || !section) return;
			tabsClean();

			btn.classList.add('is-active');
			section.classList.add('is-active');

			const masonryId = btn.getAttribute('data-gallery-id');
			console.info(this.collection);
			if (this.collection[masonryId]) {
				this.collection[masonryId].layout();
			}

			cookies.rm('anchor');
		}

		function tabsClean() {
			tabsBtnsCollection.forEach((el)=>{
				el.classList.remove('is-active');
			});
			tabsSectionsCollection.forEach((el)=>{
				el.classList.remove('is-active');
			});
		}
	}

	initFixedHead() {
		const head = document.querySelector('.js-fixed-head');

		let isFixed = false;
		window.addEventListener('scroll', (ev) => {
			const headRect = head.getBoundingClientRect();
			const y = headRect.y || headRect.top;

			if (y < window.pageYOffset) {
				this.fixHead();
				isFixed = true;
			} else {
				this.unfixHead();
				isFixed = false;
			}
		});

		window.addEventListener('resize', (ev) => {
			if (isFixed) {
				this.unfixHead();
				this.fixHead();
			}
		});
	}

	fixHead() {
		const head = document.querySelector('.js-fixed-head');
		const header = document.querySelector('header');
		const headRect = head.getBoundingClientRect();
		const headerRect = header.getBoundingClientRect();

		head.style.position = 'fixed';
		head.style.left = `${headRect.x || headRect.left}px`;
		head.style.top = `${headerRect.height}px`;
		head.style.paddingTop = `10px`;
		head.style.backgroundColor = '#fff';
		head.style.width = `${headRect.width}px`;
	}

	unfixHead() {
		const head = document.querySelector('.js-fixed-head');
		head.style.position = 'static';
		head.style.left = '';
		head.style.top = '';
		head.style.paddingTop = '';
		head.style.backgroundColor = '';
		head.style.width = '';
	}

	// Инициализация происходит для каждого отдельного набора
	// внутри обязательной обертки - tergetContainer
	//
	//  arrUrls - [] - массив урлов изображений
	// tergetContainer - Node - оболочка, в которую вставляется шаблон
	// groupName - str - название группы для сортировки - по ней фиксируем инстанс библиотеки
	init_gallery(arrUrls, tergetContainer, groupName, tags = []) {
		arrUrls.forEach((url, index) => {
			this._createImage(url, tergetContainer, groupName, tags.length ? tags[index] : false);
		});

		this.collection[groupName] = new Masonry(tergetContainer, {
			itemSelector: '.gallery-base-block',
			columnWidth: '.grid-sizer',
			percentPosition: true,
			gutter: 10,
			initLayout: false
		});

		if (tergetContainer.classList.contains('js-no-modal')) {
			this._initGalleryCardWithoutModal(tergetContainer);
		} else {
			// передать обертку в инициализацию модалок (для поиска)
			window.components['Modals'].initModalCollections(tergetContainer);
		}

		Promise.all(this.loadState)
			.then((result) => {
				console.warn(result);

				window.components['Preloader'].disablePreloader();
			});
	}

	/** Замена
	 * window.components['Modals'].initModalCollections
	 * на поведение "без комментария и модалки"
	 * код взят из window.components['Modals'].toggleSelect
	 */
	_initGalleryCardWithoutModal(tergetContainer) {
		const imgBlockCollection = [...tergetContainer.querySelectorAll('.js-modal-collection')];
		imgBlockCollection.forEach(element => {

			element.addEventListener('mouseenter', e => {
				e.target.querySelector('.js-wrapper').classList.add('is-active');
			});

			element.addEventListener('mouseleave', e => {
				e.target.querySelector('.js-wrapper').classList.remove('is-active');
			});

			element.addEventListener('click', e => {

				const card = e.currentTarget;
				const state = card.classList.contains('js-selected'); // bool
				if ((e.target.classList.contains('js-dislike') && state) || (e.target.classList.contains('js-like'))) {

					const url = e.currentTarget.getAttribute('iid');
					const group = e.currentTarget.getAttribute('data-target-group');

					if (state) {
						if (brief.selectedImages[group] && brief.selectedImages[group].includes(url)) {
							let i = brief.selectedImages[group].findIndex(x => x == url);
							if (i >= 0) brief.selectedImages[group].splice(i, 1);
							/** Комментарий к ним не предусмотрен */
							brief.imageComments[group][url] = '';
							/** Поле закостылено под другие нужды */
						}

						card.classList.remove('js-selected');

					} else {
						if (!brief.selectedImages[group]) brief.selectedImages[group] = [];
						if (!brief.selectedImages[group].includes(url)) brief.selectedImages[group].push(url);

						const cruthTitle = card.getAttribute('data-crutch-title');
						if (cruthTitle) brief.imageComments[group][url] = cruthTitle;

						card.classList.add('js-selected');
					}

					brief.save();

				}
			});

		});
	}

	clear_gallery(tergetContainer, groupName) {
		let cn = [...tergetContainer.childNodes];
		cn.map(x => {
			if (x.nodeType != 1 || x.classList.contains('gallery-base-block')) { // Учет попадания остального муора, который попадает в childNodes
				x.remove();
			}
		})
		delete this.collection[groupName];
	}

	_createImage(url, appendTo, groupName, tagsStr) {
		let imgBlock = document.createElement('div');
		let img = document.createElement('img');

		img.classList.add('gallery-base-image');
		// js-modal-collection важный целевой класс
		imgBlock.classList.add('gallery-base-block', 'js-modal-collection');

		if (brief.selectedImages[groupName] && brief.selectedImages[groupName].includes(url)) {
			imgBlock.classList.add('js-selected');

			// new
			if (brief.imageComments[groupName][url]) {
				imgBlock.classList.add('js-has-comment');
			}
		}

		// два обязательных атрибута
		imgBlock.setAttribute('iid', url);
		imgBlock.setAttribute('data-target-group', groupName);
		imgBlock.appendChild(img);

		let interactive = document.createElement('div');
		let like = document.createElement('div');
		let dislike = document.createElement('div');
		// let comment = document.createElement('div');
		interactive.classList.add('js-wrapper');
		// js-helper-targe важный класс выбирающий цель
		like.classList.add('js-like', 'js-helper-targe');
		dislike.classList.add('js-dislike', 'js-helper-targe');
		// comment.classList.add('js-comment', 'js-helper-targe');
		like.setAttribute('title', 'Добавить в избранное');
		dislike.setAttribute('title', 'Отменить выбор');

		// new
		if (typeof tagsStr === 'string') {
			const search = SpecialPhotosConfig.find(tag => tagsStr.includes(tag[brief.lang]));
			if (search) {
				imgBlock.insertAdjacentHTML('afterbegin', `<h2>${search[brief.lang].replace('-', ' ')}</h2>`);
				imgBlock.setAttribute('data-crutch-title', search[brief.lang]);
			}
		}

		interactive.appendChild(like);
		interactive.appendChild(dislike);
		// interactive.appendChild(comment);

		imgBlock.appendChild(interactive);

		//
		// img.src = `/api/images/get/${url}`;
		// img.onload = () => {
		// 	this.collection[groupName].layout();
		// };

		this.loadState.push(new Promise((resolve, reject) => {
			img.onload = () => {
				this.collection[groupName].layout();
				console.log('resolve');

				resolve({url, status: 'ok'});
			}
			img.onerror = () => resolve({url, status: 'error'});

			img.src = `${BASE_URI}/api/images/get/${url}`;
		}));
		//

		appendTo.append(imgBlock);
	}

	setCardsParams(wrapper) {
		console.log(wrapper);
		const id = wrapper.getAttribute('iid');
		const group = wrapper.getAttribute('data-target-group');

		const image = wrapper.querySelector('.js-gallery-final-img--hover');
		const imageDel = wrapper.querySelector('.js-gallery-final-img--del');

		const isCustom = id.startsWith('!custom');
		const customId = isCustom ? id.match(/!custom([0-9]{1,3})/)[1] : null;
		// устанавливает текст коммента
		// помимо этого влияет на финальное отображение карточки (её компоновка)!
		let text;

		/** Разделение логики
		 * на упрощенную для custom, with-people, without-people
		 * и общую
		 * для галерей с модалкой
		 */

		// handlers:
		image.addEventListener('mouseenter', () => {
			imageDel.classList.add('is-active');
		});

		image.addEventListener('mouseleave', () => {
			imageDel.classList.remove('is-active');
		});

		//@todo --
		imageDel.addEventListener('click', () => {
			const deleteConfirmText = window.brief.lang === "en" ? "Confirm deletion" : "Подтвердите удаление";

			const conf = confirm(deleteConfirmText);
			if (conf) {
				console.warn('img Del action!!');

				if (wrapper.parentNode && wrapper.parentNode.classList.contains('js-container')) {
					wrapper.parentNode.remove();
				} else {
					wrapper.remove();
				}

				if (isCustom) {
					components.CustomReferenceController.removeFileFromList(components.CustomReferenceController.files[customId]);
				} else {
					let i = brief.selectedImages[group].findIndex(x => x == id);
					if (i >= 0) brief.selectedImages[group].splice(i, 1);
					brief.imageComments[group][id] = '';
					brief.save();
				}
			}
		});
		//@todo --

		if (isCustom || group == 'with-people' || group == 'without-people') { // crutch
			return false;
		}

		const commentAdd = wrapper.querySelector('.js-gallery-final-comment--add');
		/**
		const commentDel = wrapper.querySelector('.js-gallery-final-comment--del');
		const area = wrapper.querySelector('.js-gallery-final-area');
		*/

		const checkboxes = [...wrapper.querySelectorAll('.js-final-block-checkboxes .form-checkbox__item')];
		const fish = wrapper.querySelector('.js-final-block-text');

		const data = window.components['Modals']._getCommentText(group, id);

		if (data.success && fish) {
			// area.value = text;
			fish.innerText = data.txt;

			wrapper.classList.add('js-block-comment--on');
		} else {
			wrapper.classList.add('js-block-comment--off');
		}

		if (checkboxes.length) {
			checkboxes.forEach(checbox => {
				if (data.fd.includes(checbox.getAttribute('data-id-cb'))) {
					checbox.style.display = 'block';
				} else {
					checbox.style.display = 'none';
				}
			});
		}

		if (commentAdd) {
			commentAdd.addEventListener('click', () => {
				// data-id-cb="composition"

				window.components['Modals'].openFinal({
					src: id,
					groupName: group,
					el: wrapper
				});

			});
		}

		/**
		 commentDel.addEventListener('click', () => {
			 console.log('commentDel action');
			 wrapper.classList.toggle('js-block-comment--on');
			 wrapper.classList.toggle('js-block-comment--off');

			 if (!isCustom) {
				 if (text || area.value.length) {
					 if (window.components['Modals'].emptyComment) {
						 window.components['Modals'].emptyComment.classList.add('is-active');
					 }

					 area.value = '';
					 brief.imageComments[group][id] = '';
					 brief.save();
				 }
			 } else {
				 area.value = '';
				 components.CustomReferenceController.files[customId].comment = '';
				 components.CustomReferenceController.setFile(components.CustomReferenceController.files[customId]);
			 }
		 });


		area.addEventListener('blur', e => {
			// наличие текста
			// есть ? - перезапись при отличном значении
			// нет - валидируем на длину
			// const diff = text ? !(text == e.target.value) : e.target.value.length > 2;

			if (e.target.value.length <= 2) {
				wrapper.classList.toggle('js-block-comment--on');
				wrapper.classList.toggle('js-block-comment--off');
			}

			if (document.querySelectorAll('.js-block-comment--off').length) {
				if (window.components['Modals'].emptyComment) {
					window.components['Modals'].emptyComment.classList.add('is-active');
				}
			} else {
				if (window.components['Modals'].emptyComment) {
					window.components['Modals'].emptyComment.classList.remove('is-active');
				}
			}

			if (!isCustom) {
				brief.imageComments[group][id] = e.target.value;
				brief.save();
			} else {
				components.CustomReferenceController.files[customId].comment = e.target.value.length <= 2 ? '' : e.target.value;
				components.CustomReferenceController.setFile(components.CustomReferenceController.files[customId]);
			}
		}); */

	}
}

module.exports = Gallery;
